import React from 'react';
import LoadingArc from '@/components/loading-arc';

export default function LoadingScreen() {
  return (
    <div>
      <LoadingArc />
      <style jsx>
        {`
          div {
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-color: var(--color-white);
          }
        `}
      </style>
    </div>
  );
}
