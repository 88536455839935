import React, {
  useState, createContext, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  mockActionItems,
  mockGradingGroupItems,
  mockAttributes,
  mockRules,
} from './mocks';

export const E2EDemoContext = createContext();

const E2EDemoContextProvider = ({ children }) => {
  const [actions, setActions] = useState([]);
  const [gradingGroups, setGradingGroups] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [rules, setRules] = useState([]);

  // This is in a useEffect to be more aligned to any future fetch request method
  useEffect(() => {
    setActions(mockActionItems);
    setGradingGroups(mockGradingGroupItems);
    setAttributes(mockAttributes);
    setRules(mockRules);
  }, []);

  const addAction = (action) => {
    const updatedList = [...actions];
    updatedList.push({ ...action, itemId: `w${updatedList.length + 1}` });
    setActions(updatedList);
  };

  const updateAction = (itemName, update) => {
    const updatedList = [...actions];
    updatedList.forEach((li, index) => {
      if (li.title === itemName) {
        updatedList[index] = { ...updatedList[index], ...update };
      }
    });
    setActions(updatedList);
  };

  const updateActionList = (newList) => {
    setActions(newList);
  };

  const addGradingGroup = (gradingGroup) => {
    const updatedList = [...gradingGroups];
    updatedList.push({ ...gradingGroup, itemId: `w${updatedList.length + 1}` });
    setGradingGroups(updatedList);
  };

  const updateGradingGroup = (update) => {
    const updatedList = [...gradingGroups];
    updatedList.forEach((li, index) => {
      if (li.itemId === update.itemId) {
        updatedList[index] = { ...update };
      }
    });
    setGradingGroups(updatedList);
  };

  const updateGradingGroupList = (newList) => {
    setGradingGroups(newList);
  };

  const updateAttributeList = (newList) => {
    setAttributes(newList);
  };

  const addAttribute = (attribute) => {
    const updatedList = [...attributes];
    updatedList.push(attribute);
    setAttributes(updatedList);
  };

  const updateAttribute = (itemName, update) => {
    const updatedList = [...attributes];
    updatedList.forEach((li, index) => {
      if (li.title === itemName) {
        updatedList[index] = { ...updatedList[index], ...update };
      }
    });
    setAttributes(updatedList);
  };

  const addRule = (rule) => {
    const updatedList = [...rules];
    updatedList.push({ ...rule, itemId: `w${updatedList.length + 1}` });
    setRules(updatedList);
  };

  const updateRule = (rule) => {
    const updatedList = [...rules];
    updatedList.forEach((li, index) => {
      if (li.itemId === rule.itemId) {
        updatedList[index] = { ...updatedList[index], ...rule };
      }
    });
    setRules(updatedList);
  };

  const updateRuleList = (newList) => {
    setRules(newList);
  };

  const value = useMemo(() => ({
    actions,
    updateAction,
    addAction,
    updateActionList,
    gradingGroups,
    updateGradingGroup,
    addGradingGroup,
    updateGradingGroupList,
    attributes,
    updateAttribute,
    addAttribute,
    updateAttributeList,
    rules,
    addRule,
    updateRule,
    updateRuleList,
  }), [actions, gradingGroups, attributes, rules]);

  return (
    <E2EDemoContext.Provider value={value}>
      {children}
    </E2EDemoContext.Provider>
  );
};

E2EDemoContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default E2EDemoContextProvider;
