import getEnvVar from '@/utilities/environment-variable';

const getTiersMapping = () => {
  const TIERS_API_KEY = getEnvVar('REACT_APP_TIERS_API_KEY');
  return {
    1: {
      CONSUMER_CHARGE_ACCOUNT: getEnvVar(
        'REACT_APP_TIER_1_CONSUMER_CHARGE_ACCOUNT',
      ),
      MLID: getEnvVar('REACT_APP_TIER_1_MLID'),
      API_KEY: TIERS_API_KEY,
    },
    2: {
      CONSUMER_CHARGE_ACCOUNT: getEnvVar(
        'REACT_APP_TIER_2_CONSUMER_CHARGE_ACCOUNT',
      ),
      MLID: getEnvVar('REACT_APP_TIER_2_MLID'),
      API_KEY: TIERS_API_KEY,
    },
    3: {
      CONSUMER_CHARGE_ACCOUNT: getEnvVar(
        'REACT_APP_TIER_3_CONSUMER_CHARGE_ACCOUNT',
      ),
      MLID: getEnvVar('REACT_APP_TIER_3_MLID'),
      API_KEY: TIERS_API_KEY,
    },
    4: {
      CONSUMER_CHARGE_ACCOUNT: getEnvVar(
        'REACT_APP_TIER_4_CONSUMER_CHARGE_ACCOUNT',
      ),
      MLID: getEnvVar('REACT_APP_TIER_4_MLID'),
      API_KEY: TIERS_API_KEY,
    },
  };
};

export default getTiersMapping;
