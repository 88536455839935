import GB from '@/public/icons/gb.svg';
import AR from '@/public/icons/ar.svg';
import JP from '@/public/icons/jp.svg';
import NL from '@/public/icons/nl.svg';
import FR from '@/public/icons/fr.svg';
import ES from '@/public/icons/es.svg';

/**
 * Gets a locale code and returns the corresponding language icon
 * @param {string} localeCode - 'en' | 'ar' | 'ja'
 * @returns {JSX.Element}
 */

const mapLanguageIcons = (localeCode) => {
  switch (localeCode) {
    case 'en':
      return GB;
    case 'ar':
      return AR;
    case 'ja':
      return JP;
    case 'nl':
      return NL;
    case 'fr':
      return FR;
    case 'es':
      return ES;
    default:
      return null;
  }
};

export default mapLanguageIcons;
