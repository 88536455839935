/* eslint-disable no-console */
import axios from 'axios';
import getEnvVar from '@/utilities/environment-variable';
import { isEmpty } from '@/utilities/validation';
import { isNil } from 'lodash';
import compileChangeObject from './compile-signup-change-object';
import { errorHandler } from '../error-handling';

export default async (changeArray, token, rootOrgId, productName, canSkipPrereg) => {
  if (isEmpty(changeArray)) return;
  const body = {
    ...compileChangeObject(changeArray, rootOrgId, productName),
  };

  try {
    if (canSkipPrereg && isNil(body.applicant.customersToUse)) {
      return;
    }
    await axios.post(`${getEnvVar('REACT_APP_DODDLE_API_URL')}/v1/prereg/`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          'cache-control': 'no-cache',
          Authorization: token,
        },
      });
  } catch (e) {
    console.log('Could not sign up the company');
    console.log(e);
    throw new Error(errorHandler.checkInvalidTradingName(e));
  }
};
