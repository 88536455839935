import React, {
  createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { MerchantContext } from '@/contexts/merchant-context';
import { AdminContext } from '@/contexts/admin-context';

export const RoutingContext = createContext();

/**
 * Routing context
 * Handles all of the logic related to routes
 *
 * @param {node} children The context children
 */
const RoutingContextProvider = ({ children }) => {
  const router = useRouter();
  const { getFeature, remoteConfigFetched } = useContext(MerchantContext);
  const {
    getAdminFeature,
    isAdmin,
    isAdminReviewMode,
    auth,
  } = useContext(AdminContext);

  let configNavigation = (isAdmin && !isAdminReviewMode) ? getAdminFeature('navigation') : getFeature('navigation');

  if (router.pathname === '/login' && auth?.singleSignOnNoReDirect) {
    configNavigation = getAdminFeature('navigation');
  }

  const getNavigationForRoute = (route) => {
    if (!configNavigation?.length) {
      return {};
    }
    let genericRoute;
    if (isAdminReviewMode) {
      genericRoute = route?.replace('merchants/[merchantId]/', '').replace('[pageId]', router.query.pageId);
    } else {
      genericRoute = route?.replace('[companyId]/', '');
    }

    const flattenedNav = configNavigation.map((nav) => (nav.subItems ? [...nav.subItems] : nav)).flat();
    const matchingRoute = flattenedNav.filter((nav) => nav.url === genericRoute)?.[0];
    return matchingRoute ?? {};
  };

  const showHeaderOnlyForRoute = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.showHeaderOnlyForRoute ?? false;
  };

  if (!isAdmin && !remoteConfigFetched) {
    return (
      <RoutingContext.Provider value={{
        showNavigation: () => false,
        showInMenu: () => false,
        showFooter: () => true,
        showRoute: () => true,
        showHeaderOnlyForRoute,
        showInReviewMode: () => true,
        showPublishButton: () => false,
        showPublishButtonAdminMode: () => false,
        showVersion: () => false,
        showFooterLogo: () => false,
      }}
      >
        {children}
      </RoutingContext.Provider>
    );
  }

  const showRoute = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.showRoute ?? true;
  };

  const showNavigation = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.showNavigation ?? true;
  };

  const showInMenu = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.showInMenu ?? true;
  };

  const showFooter = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.showFooter ?? false;
  };

  const showInReviewMode = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.showInReviewMode ?? true;
  };

  const showPublishButton = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return !!navigationObj.showPublishButton;
  };

  const showPublishButtonAdminMode = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return !!navigationObj.showPublishButtonAdminMode;
  };

  const showVersion = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.showVersion ?? true;
  };

  const showFooterLogo = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return !!navigationObj.showFooterLogo;
  };

  const getConfigRefetchDelay = (route = router.pathname) => {
    const navigationObj = getNavigationForRoute(route);
    return navigationObj.configRefetchDelay;
  };

  return (
    <RoutingContext.Provider value={{
      showNavigation,
      showInMenu,
      showFooter,
      showInReviewMode,
      showPublishButton,
      showPublishButtonAdminMode,
      showRoute,
      showVersion,
      showFooterLogo,
      showHeaderOnlyForRoute,
      getConfigRefetchDelay,
    }}
    >
      {children}
    </RoutingContext.Provider>
  );
};

RoutingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RoutingContextProvider;
