/* eslint-disable no-console */
import { isEmpty } from '@/utilities/validation';
import compileChangeObject from './compile-change-object';
import { updateDefaultWarehouse, updateWarehouseReq } from './warehouse';
import { enableCarrierInSingleCarrierMode } from './company-carrier';

export default async (changeArray, merchantId, token, rootOrgId, getFeature, locationId, getConfigValue, productName) => {
  if (isEmpty(changeArray) || isEmpty(locationId)) return;

  const { multiCarrierAddressesApiEnabled } = getFeature('addresses');

  const hasNoDefaultWarehouse = getConfigValue('warehousesConfig')?.filter((location) => location.default).length === 0;

  const body = {
    enabled: multiCarrierAddressesApiEnabled ? undefined : true,
    ...compileChangeObject(changeArray),
  };

  try {
    await updateWarehouseReq({
      locationId,
      rootOrgId,
      merchantId,
      body,
      token,
      multiCarrierAddressesApiEnabled,
    });
    /**
     * When adding credentials for the first warehouse (merchant has no default warehouse yet) -
     * set the warehouse as default and enable the carrier
     * (for multi-carrier this is handled on the BE during update-warehouse request)
     */
    if (!multiCarrierAddressesApiEnabled) {
      if (hasNoDefaultWarehouse) {
        await updateDefaultWarehouse({
          rootOrgId, merchantId, token, locationId, body,
        });
      }
      // check if there's a pending carrier (single carrier mode) that needs to be activated
      const { carriersWithPendingStatus = [] } = getFeature('carriers') || {};
      const merchantCarriers = getConfigValue('carriersConfig');
      const pendingCarrier = merchantCarriers?.find((carrier) => carriersWithPendingStatus.includes(carrier.carrierCompanyId));

      if (pendingCarrier) {
        await enableCarrierInSingleCarrierMode({
          merchantId,
          getConfigValue,
          carrierCompanyId: pendingCarrier.carrierCompanyId,
          rootOrgId,
          token,
          productName,
        });
      }
    }
  } catch (e) {
    console.log('Could not update the warehouse bindings');
    console.log(e);
    throw new Error(e);
  }
};
