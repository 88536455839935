/* eslint-disable no-console */
import axios from 'axios';
import getEnvVar from '@/utilities/environment-variable';
import { isEmpty } from '@/utilities/validation';
import logging from '@/utilities/telemetry/sentry';
import compileChangeObject from './compile-onboard-change-object';

const { logEvent, logException } = logging;
export default async (changeArray, token, rootOrgId, productName, getAdminConfigValue, getFeature, sharedData) => {
  if (isEmpty(changeArray)) return;
  const body = {
    ...compileChangeObject(changeArray, sharedData, getAdminConfigValue, getFeature),
  };

  try {
    await axios.post(`${getEnvVar('REACT_APP_DODDLE_API_URL')}/v1/returns-portal-service/${getAdminConfigValue('carrierName')}/onboard/`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          'cache-control': 'no-cache',
          Authorization: token,
        },
      });
  } catch (e) {
    logEvent({
      eventName: 'onboarding_failed',
      message: 'Could not onboard the company',
    });
    logException({
      eventName: 'onboarding_failed',
      exception: e,
    });
    throw new Error(e);
  }
};
