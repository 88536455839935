import React, {
  useState, createContext, useEffect, useContext,
} from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import getEnvVar from '@/utilities/environment-variable';
import { MerchantContext } from '@/contexts/merchant-context';
import 'dayjs/locale/ja';
import 'dayjs/locale/ar';
import { AdminContext } from '../admin-context';
import languageInfo from './language-info.json';
import mapLanguageIcons from './utils';

dayjs.extend(localeData);

export const LocaleContext = createContext();

const LocaleContextProvider = ({ children }) => {
  // Get the language direction that maps to a code e.g. ar => rtl
  const getLanguageDirection = (code) => languageInfo?.[code]?.direction ?? 'ltr';

  const { t, i18n } = useTranslation();
  const merchantConfig = useContext(MerchantContext);
  const adminConfig = useContext(AdminContext);

  const getConfigValue = (key) => {
    if (key in (adminConfig || {})) return adminConfig[key];
    if (key in (merchantConfig || {})) return merchantConfig[key];
    return null;
  };

  const defaultLanguage = getConfigValue('defaultLanguage') || getEnvVar('REACT_APP_DEFAULT_LANGUAGE_OVERRIDE') || 'en';
  const dateFormat = getConfigValue('dateFormat');
  const [currentLocale, setCurrentLocale] = useState(defaultLanguage);
  const [loadedLocale, setLoadedLocale] = useState();
  const [textDirection, setTextDirection] = useState(getLanguageDirection(defaultLanguage));

  const router = useRouter();

  const getLanguagePack = async (newLocale) => {
    const carrier = getConfigValue('carrier');
    const carrierName = typeof carrier === 'string' ? carrier : carrier?.name;
    const response = await axios.get(`/api/locale?locale=${newLocale}&carrier=${carrierName}`);
    i18n.addResourceBundle(newLocale, 'translation', response.data, true, true);
  };

  const getMomentCode = (code) => languageInfo?.[code]?.momentCode ?? 'en';

  const setLocale = () => {
    const code = getMomentCode(currentLocale);
    moment.locale(code);
    dayjs.locale(code);
  };

  const changeLocale = async (newLocale) => {
    await getLanguagePack(newLocale);
    await i18n.changeLanguage(newLocale);
    setLoadedLocale(newLocale);
  };

  const translate = (translationKey, options) => {
    const translation = t(translationKey, options);
    if (translation === '') return '';

    return (translation === translationKey) ? '...' : translation;
  };

  const getLanguageName = (code) => languageInfo?.[code]?.name ?? '';
  const getLanguageIcon = (code) => mapLanguageIcons(code);

  const getLanguageOptions = () => {
    const languageCodes = getConfigValue('languages');
    if (!languageCodes) return [];
    return languageCodes.map((code) => ({
      code,
      title: getLanguageName(code),
      direction: getLanguageDirection(code),
      icon: getLanguageIcon(code),
      momentCode: getMomentCode(code),
      onClick: () => {
        setCurrentLocale(code);
        setTextDirection(getLanguageDirection(code));
      },
    }));
  };

  const contextValue = {
    currentLocale,
    setCurrentLocale,
    loadedLocale,
    textDirection,
    setTextDirection,
    translate,
    getLanguageName,
    getLanguageDirection,
    getMomentCode,
    dateFormat,
    getLanguageOptions,
    getLanguageIcon,
  };
  const getLocaleContextValue = (key) => contextValue[key];

  useEffect(() => {
    void changeLocale(currentLocale);
    setLocale();
  }, [currentLocale]);

  useEffect(() => {
    setLocale();
  }, [router]);

  return (
    <LocaleContext.Provider value={{ ...contextValue, getLocaleContextValue }}>
      {children}
    </LocaleContext.Provider>
  );
};

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleContextProvider;
