import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MerchantContext } from '@/contexts/merchant-context';
import { AdminContext } from '@/contexts/admin-context';
import { RoutingContext } from '@/contexts/routing-context';
import ChevronDown from '@/public/icons/chevron-down.svg';
import { AuthContext } from '@/contexts/auth-context';
import { LocaleContext } from '@/contexts/locale-context';
import { ThemeContext } from '@/contexts/theme-context';
import SideNavigationSelection from './side-navigation-selection';

export default function SideNavigation({ selectedRoute }) {
  const router = useRouter();
  const { translate } = useContext(LocaleContext);
  const { getNavigationRoutes } = useContext(MerchantContext);
  const {
    getAdminFeature, isAdmin: isAdminMode, isAdminReviewMode, adminModeMerchantId, getAuth, getLoginWithExternal,
  } = useContext(AdminContext);
  const { primaryColor } = useContext(ThemeContext);

  const isAdmin = isAdminMode && !isAdminReviewMode;

  const externalLogin = getLoginWithExternal();
  const ssoEnabled = getAuth()?.singleSignOnNoReDirect && externalLogin;
  const { clearAuth } = useContext(AuthContext);
  const { showInMenu, showInReviewMode } = useContext(RoutingContext);
  const configRoutes = isAdmin ? getAdminFeature('navigation') : getNavigationRoutes();
  const merchantId = router.query?.companyId;
  // Returns valid url string, e.g. '/TEST_MERCHANT/details'
  const getLinkUrl = (configUrl) => {
    if (isAdmin) {
      return configUrl;
    }
    if (isAdminReviewMode) {
      return `/merchants/${adminModeMerchantId}${configUrl}`;
    }
    return merchantId ? `/${merchantId}${configUrl}` : configUrl;
  };

  // Returns unresolved version of the url, e.g. '/[companyId]/details'
  const getConfigUrl = (urlValue) => {
    if (isAdmin) {
      return urlValue;
    }
    if (isAdminReviewMode) {
      return `/merchants/[merchantId]${urlValue}`;
    }
    return `/[companyId]${urlValue}`;
  };

  const routes = [...configRoutes || []];
  const dropdownSections = routes.filter((route) => route.subItems);
  const isDropdownOpen = (dropdown) => {
    const urls = dropdown.subItems.map((si) => getConfigUrl(si.url));
    const isOpen = urls.includes(selectedRoute) || dropdownSections.some((el) => el.isDefaultOpen === true);
    return isOpen;
  };

  const [isOpen, setIsOpen] = useState({});

  useEffect(() => {
    const defaultOpenStates = {};
    dropdownSections.forEach((dds) => {
      defaultOpenStates[dds.title] = isDropdownOpen(dds);
    });
    setIsOpen(defaultOpenStates);
  }, [configRoutes?.length, selectedRoute]);

  if (isAdmin) {
    routes.push({ title: 'navigation.logout', onClick: () => clearAuth(ssoEnabled ? 'constants.apiErrors.loggedOut' : null) });
  }

  const onKeyDown = (func) => (event) => {
    if (event.key === 'Enter') {
      func();
    }
  };

  const toggleIsOpen = (item) => setIsOpen({ ...isOpen, [item]: !isOpen[item] });

  return (
    <div id="side-navigation" className={`${isAdminReviewMode ? 'review-mode' : ''}`}>
      {routes.map((route) => {
        if (!showInMenu(route.url)) return null;
        if (isAdminReviewMode && !showInReviewMode(route.url)) return null;
        if (route.subItems) {
          return (
            <div
              key={route.title}
              className={`dropdown-section ${isOpen[route.title] ? '' : 'closed'}`}
            >
              <div
                key={`${route.url}-title`}
                className="dropdown-title"
                onClick={() => toggleIsOpen(route.title)}
                onKeyDown={onKeyDown(() => toggleIsOpen(route.title))}
                role="button"
                tabIndex={0}
              >
                <div>{translate(route.title)}</div>
                <div className={`arrow-down ${isOpen[route.title] ? 'open' : ''}`}><ChevronDown /></div>
              </div>
              <div
                key={`${route.url}-subitems`}
                className={`dropdown-options ${isOpen[route.title] ? '' : 'closed'}`}
              >
                {route.subItems.map((subItem) => (subItem.showRoute !== false
                      && (
                      <SideNavigationSelection
                        key={subItem.url}
                        isSelected={selectedRoute.includes(subItem.url)}
                        url={getLinkUrl(subItem.url)}
                        title={translate(subItem.title)}
                      />
                      )
                ))}
              </div>
            </div>
          );
        }
        if (route.url) {
          const selected = selectedRoute.includes(route.url);
          return (
            <div
              key={route.url}
              className={`single-item-section${selected ? ' selected' : ''}`}
            >
              <Link href={getLinkUrl(route.url)}>{translate(route.title)}</Link>
            </div>
          );
        }
        if (route.onClick) {
          return (
            <div
              className="single-item-section"
              key={route.title}
            >
              <div
                className="item-click"
                onClick={route.onClick}
                onKeyDown={onKeyDown(() => {
                  route.onClick();
                })}
                tabIndex={0}
                role="button"
              >
                {translate(route.title)}
              </div>
            </div>
          );
        }
        return <></>;
      })}
      <style jsx>
        {`

          .arrow-down {
            position: absolute;
            display: inline-block;
            right: 5px;
            transition: transform .24s;
          }

          .arrow-down.open {
            transform: rotate(180deg);
          }
          
          #side-navigation {
            position: fixed;
            top: 70px;
            border-right: 1px solid #e9eaea;
            height: calc(100vh - 71px);
            left: 0;
            display: flex;
            flex-direction: column;
            width: 280px;
            max-width: 280px;
            overflow-y: auto;
          }

          #side-navigation.review-mode {
            top: 120px;
            height: calc(100vh - 121px);
          }

          .single-item-section, .dropdown-section {
            padding: 7% 10%;
            border-bottom: 1px solid #e9eaea;
            display: flex;
            flex-direction: column;
            position: relative;
          }

          .single-item-section:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 25px;
            left: 10px;
          }

          .single-item-section.selected:before {
            animation: pulse 1s;
            background-color: ${primaryColor};
            transition: opacity 1s linear;
          }

          .single-item-section:hover {
            opacity: 0.6;
          }

          .single-item-section:last-child {
            border-bottom: none;
          }

          a {
            color: #000;
            opacity: 1;
            width: 100%;
            display: block;
            padding: 10px 0;
          }

          a:hover {
            opacity: 0.6;
          }

          .dropdown-section {
            transition: height 0.3s linear;
          }

          .dropdown-section.closed {
            height: 58px;
            transition: height 0.3s linear;
          }

          .dropdown-title {
            position: relative;
            cursor: pointer;
            display: block;
          }

          .dropdown-title div {
            display: inline-block;
          }

          .dropdown-title:hover {
            opacity: 0.6;
          }

          .dropdown-options {
            padding-top: 20px;
            overflow: hidden;
            transition: opacity 0.3s linear;
          }

          .dropdown-options.closed {
            opacity: 0;
            transition: opacity 0.3s linear;
            display: none;
          }
          .item-click {
            cursor: pointer;
          }

          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(112,112,112, 0.4);
            }
            70% {
              box-shadow: 0 0 0 7px rgba(112,112,112, 0);
            }
            100% {
              box-shadow: 0 0 0 0 rgba(112,112,112, 0);
            }
          }
        `}
      </style>
    </div>
  );
}

SideNavigation.defaultProps = {
  selectedRoute: '/',
};

SideNavigation.propTypes = {
  selectedRoute: PropTypes.string,
};
