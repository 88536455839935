import merge from 'deepmerge';
import { get, isFunction, set } from 'lodash';
import { isEmpty } from '../validation';
// list of properties that need to be sent as integers
const integerTypeValues = [
  'PURCHASE_WARRANTY_PERIOD',
  'DOMESTIC_PRICING_TIERS',
  'INTERNATIONAL_PRICING_TIERS',
  'DUE_DATE_DAYS',
];
// list of properties that need to be escaped
const regexTypeValues = [
  'ORDER_ID_REGEX',
  'RAN_REGEX',
];

const defaultRegex = '[\\s\\S]{1,50}';

const getPaymentTypeChange = (reasonsList) => {
  const PAYMENT_TYPES = {
    MERCHANT_PAID: 'MERCHANT_PAID',
    BOTH_PAID: 'BOTH_PAID',
    CONSUMER_PAID: 'CONSUMER_PAID',
  };
  let selectedPaymentType = PAYMENT_TYPES.BOTH_PAID;

  if (reasonsList.every((reason) => reason.paymentRequired)) {
    selectedPaymentType = PAYMENT_TYPES.CONSUMER_PAID;
  } else if (reasonsList.every((reason) => !reason.paymentRequired)) {
    selectedPaymentType = PAYMENT_TYPES.MERCHANT_PAID;
  }

  return selectedPaymentType;
};

export const replaceValue = (string, value) => {
  if (typeof value === 'string') {
    if (value === '') {
      return string.replace('"%VALUE%"', null);
    }
    return string.replace('%VALUE%', value);
  }
  if (typeof value === 'boolean') {
    return string.replace('"%VALUE%"', JSON.stringify(value));
  }
  if (value?.isRegex) {
    if (value?.value === '') {
      return string.replace('"%VALUE%"', JSON.stringify(defaultRegex));
    }
    return string.replace('"%VALUE%"', JSON.stringify(value.value));
  }
  if (Array.isArray(value)) {
    return string.replace('"%VALUE%"', JSON.stringify(value));
  }
  if (!isEmpty(value)) {
    return string.replace('"%VALUE%"', JSON.stringify(value));
  }
  return string.replace('"%VALUE%"', value);
};

export default (changeArray, productName, getConfigValue, getFeature, sharedData, getAdminConfigValue) => {
  if (!changeArray?.length) return {};

  let changeObject = {};
  changeArray.forEach((change) => {
    const keyString = JSON.stringify(change.key);
    const keyStringWithProduct = keyString.replace('%PRODUCT%', productName).replace('%COUNTRYCODE%', sharedData?.countryCode);
    let changeValue = change.value;

    if (integerTypeValues.includes(change.valueId)) {
      changeValue = Number(change.value);
    }

    if (regexTypeValues.includes(change.valueId)) {
      changeValue = { value: change.value, isRegex: true };
    }

    const keyStringWithValue = replaceValue(keyStringWithProduct, changeValue);
    const newChangeObject = JSON.parse(keyStringWithValue);

    // Product updates need this value to always be sent
    if (newChangeObject.products?.[productName]) {
      newChangeObject.products[productName].enabled = true;
    }

    // Regex updates need this value to always be sent
    if (regexTypeValues.includes(change.valueId)) {
      newChangeObject.products[productName].returnReasons.enabled = true;
    }

    // When switching a merchant from non-integrated to integrated
    // orderId and validationRegex needs to be reset to true in order for Consumer Journey to be passable.
    // Otherwise start page on consumer journey expects Order Id field to be present and filled in but there is no way to enable it
    // in admin portal for integrated merchants
    const updateCompanyWithSideEffects = isFunction(getAdminConfigValue) && (getAdminConfigValue('updateCompanyWithSideEffects') || getConfigValue('isIntegrated'));
    if (updateCompanyWithSideEffects && change.valueId === 'CUSTOMER_JOURNEY') {
      if (change.value) {
        const sharedDataReturnFormFields = get(sharedData, `merchantData.products.${productName}.returnFormFields`);
        newChangeObject.products[productName].returnFormFields = {
          ...sharedDataReturnFormFields,
          orderId: true,
        };
        newChangeObject.products[productName].returnReasons = {
          enabled: true,
          validationRegex: defaultRegex,
        };
      }
    }

    // BE requires returnFormFields to be full object even if only one property has changed
    if (newChangeObject.products?.[productName]?.returnFormFields) {
      const configFormFields = getConfigValue('returnFormFields');
      newChangeObject.products[productName].returnFormFields = {
        ...configFormFields,
        ...changeObject.products?.[productName]?.returnFormFields,
        ...newChangeObject.products?.[productName]?.returnFormFields,
      };
    }

    if (newChangeObject.products?.[productName]?.returnReasons?.reasons) {
      const isPaymentsEnabled = getFeature('paymentAndReasonCodes')?.sections?.['payment-method'];
      const mappedReasons = newChangeObject.products[productName].returnReasons.reasons.map((reason) => ({
        code: reason.code,
        description: reason.title,
        enabled: reason.enabled,
        paymentRequired: reason.additionalToggleValue,
        paymentRequirements: reason.internationalPaymentRequirements || {},
      }));
      newChangeObject.products[productName].returnReasons.reasons = mappedReasons;
      newChangeObject.products[productName].returnReasons.enabled = true;
      if (isPaymentsEnabled) {
        newChangeObject.products[productName].customerPayments = {
          ...newChangeObject.products[productName].customerPayments,
          paymentType: getPaymentTypeChange(mappedReasons),
        };
      }
    }

    // customerPayments updates need this value to always be sent
    if (change.type === 'COMPANY' && newChangeObject.products?.[productName]?.customerPayments) {
      newChangeObject.products[productName].customerPayments.isEnabled = true;
    }

    if (newChangeObject.products?.[productName]?.countriesEnabled) {
      const { homeCountryCode } = getFeature('international');
      const portalActive = getConfigValue('portalActive');

      const countriesObject = {};
      newChangeObject.products[productName].countriesEnabled.forEach((country) => {
        countriesObject[country.code] = country.value;
      });
      newChangeObject.products[productName].countriesEnabled = countriesObject;
      newChangeObject.products[productName].consumerUrlLive = countriesObject[homeCountryCode] ?? portalActive;
    }

    if (change.valueId?.includes('CUSTOM_ATTRIBUTE_OPTIONS')) {
      set(newChangeObject, 'valueId', change.valueId);
    }

    changeObject = merge(changeObject, newChangeObject);
  });

  /**
   * handles carrier config update on a company record
   * (single-carrier case, for multi-carrier use 'company-carrier' handler)
   */
  if (JSON.stringify(changeObject).includes('%CURRENT_CARRIER%')) {
    const currentCarriers = getConfigValue?.('carriersConfig')
      || get(sharedData, `merchantData.products.${productName}.carrierConfig.carriers`)
      || [];

    const updatedCarrier = {
      ...currentCarriers[0],
      ...get(changeObject, `products.${productName}.carrierConfig.carriers.%CURRENT_CARRIER%`, {}),
    };
    set(changeObject, `products.${productName}.carrierConfig.carriers`, [updatedCarrier]);
  }

  return changeObject;
};
