import { useState, useEffect } from 'react';
import axios from 'axios';
import { Base64 } from 'js-base64';
import getEnvVar from '@/utilities/environment-variable';

export default function useAppAuth() {
  const apiUrl = getEnvVar('REACT_APP_DODDLE_API_URL');
  const authApiString = `${getEnvVar('REACT_APP_DODDLE_API_KEY')}:${getEnvVar('REACT_APP_DODDLE_API_SECRET')}`;
  const auth = Base64.encode(authApiString);
  const [authData, setAuthData] = useState(null);
  const [error, setError] = useState(null);

  const permissions = [
    'auth_staff_password',
    'auth_refresh_token',
    'authmanagement_staff:password_reset',
    'authmanagement_staff:password_reset_trigger',
    'prereg:write',
    'prereg:read',
    'returns_portal_service:app:admin',
  ];

  const makeAuthRequest = async () => {
    const body = {
      grant_type: 'client_credentials',
      scope: [...permissions].join(' '),
    };

    try {
      const response = await axios.post(`${apiUrl}/v2/oauth/token`, body, {
        headers: {
          Authorization: `Basic ${auth}`,
        },
      });
      setAuthData(response.data);
    } catch (exception) {
      setError(true);
    }
  };

  const data = {
    authData,
    authToken: authData ? `${authData.token_type} ${authData.access_token}` : null,
    error,
  };

  useEffect(() => {
    makeAuthRequest();
  }, []);

  return [data];
}
