export const mockActionItems = [
  { itemId: 'd1', title: 'Send to Warehouse', active: true },
  { itemId: 'd2', title: 'Send to Repair', active: true },
  { itemId: 'd3', title: 'Put Away', active: true },
  { itemId: 'd4', title: 'Repackage Product', active: true },
  { itemId: 'd5', title: 'Return to Vendor', active: true },
  { itemId: 'd6', title: 'Stock', active: true },
  { itemId: 'd7', title: 'Refurbishment', active: true },
  { itemId: 'd8', title: 'Charity/Liquidation/Destroy', active: true },
  { itemId: 'd9', title: 'Quarantine', active: true },
];

export const mockGradingGroupItems = [
  {
    itemId: 'pg1',
    title: 'Cameras',
    active: true,
    conditions: [
      {
        fact: 'product', operator: 'fullMatch', path: '$.categories', value: ['Cameras'],
      },
    ],
  },
  {
    itemId: 'pg2',
    title: 'Phones',
    active: true,
    conditions: [
      {
        fact: 'product', operator: 'fullMatch', path: '$.categories', value: ['Mobile Phones'],
      },
    ],
  },
  {
    itemId: 'pg3',
    title: 'Clothes',
    active: true,
    conditions: [
      {
        fact: 'product', operator: 'fullMatch', path: '$.categories', value: ['t-shirts', 'trousers'],
      },
    ],
  },
  {
    itemId: 'pg4',
    title: 'Electronics',
    active: true,
    conditions: [
      {
        fact: 'product', operator: 'fullMatch', path: '$.categories', value: ['Technology'],
      },
    ],
  },
  {
    itemId: 'pg5',
    title: 'Fashion',
    active: true,
    conditions: [
      {
        fact: 'product', operator: 'fullMatch', path: '$.categories', value: ['fashion'],
      },
    ],
  },
  {
    itemId: 'pg6',
    title: 'Shoes',
    active: true,
    conditions: [
      {
        fact: 'product', operator: 'fullMatch', path: '$.categories', value: ['shoes'],
      },
    ],
  },
];

export const mockAttributes = [
  {
    itemId: 'q1', title: 'Open', prompt: 'Is the item in original packaging?', active: true,
  },
  {
    itemId: 'q2', title: 'Complete', prompt: 'Is the product complete?', active: true,
  },
  {
    itemId: 'q3', title: 'Functional', prompt: 'Is the item damaged?', active: true,
  },
  {
    itemId: 'q4', title: 'Condition', prompt: 'Can the item be repaired?', active: true,
  },
  {
    itemId: 'q5', title: 'Fixable', prompt: 'Can the item be fixed?', active: true,
  },
  {
    itemId: 'q6', title: 'Verification', prompt: 'Has the right item been returned?', active: true,
  },
];

export const mockRules = [
  {
    itemId: 'w1',
    gradingGroup: 'pg2',
    conditions: [{
      fact: 'rps-demo-attribute', path: 'q1', operator: 'fullMatch', value: 'True',
    },
    {
      fact: 'rps-demo-attribute', path: 'q3', operator: 'fullMatch', value: 'True',
    }],
    then: 'd5',
    else: 'd2',
    isWarehouse: true,
    isStores: false,
    active: true,
  },
  {
    itemId: 'w2',
    gradingGroup: 'pg3',
    conditions: [{
      fact: 'rps-demo-attribute', path: 'q3', operator: 'fullMatch', value: 'True',
    },
    {
      fact: 'rps-demo-attribute', path: 'q1', operator: 'fullMatch', value: 'True',
    }],
    then: 'd5',
    else: 'd2',
    isWarehouse: true,
    isStores: false,
    active: true,
  },
  {
    itemId: 'w3',
    gradingGroup: 'pg1',
    conditions: [{
      fact: 'rps-demo-attribute', path: 'q2', operator: 'fullMatch', value: 'True',
    },
    {
      fact: 'rps-demo-attribute', path: 'q3', operator: 'fullMatch', value: 'True',
    }],
    then: 'd3',
    else: 'd1',
    isWarehouse: false,
    isStores: true,
    active: true,
  },
  {
    itemId: 'w4',
    gradingGroup: 'pg3',
    conditions: [{
      fact: 'rps-demo-attribute', path: 'q3', operator: 'fullMatch', value: 'True',
    },
    {
      fact: 'rps-demo-attribute', path: 'q1', operator: 'fullMatch', value: 'True',
    }],
    then: 'd3',
    else: 'd1',
    isWarehouse: false,
    isStores: true,
    active: true,
  },
  {
    itemId: 'w5',
    gradingGroup: 'pg5',
    conditions: [{
      fact: 'rps-demo-attribute', path: 'q6', operator: 'fullMatch', value: 'True',
    },
    {
      fact: 'rps-demo-attribute', path: 'q5', operator: 'fullMatch', value: 'True',
    },
    {
      fact: 'rps-demo-attribute', path: 'q2', operator: 'fullMatch', value: 'True',
    }],
    then: 'd6',
    else: 'd7',
    isWarehouse: true,
    isStores: false,
    active: true,
  },
];
