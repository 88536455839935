import { omit } from 'lodash';
import compileChangeObject from './compile-change-object';

export default (changeArray, rootOrgId, productName) => {
  let changeObject = {};

  changeObject = compileChangeObject(changeArray, productName);

  changeObject.organisationId = rootOrgId && rootOrgId;
  changeObject.product = productName && productName;

  if (changeObject.applicant?.firstName && changeObject.applicant?.lastName) {
    changeObject.applicant.contactName = `${changeObject.applicant?.firstName} ${changeObject.applicant?.lastName}`;
  }
  changeObject.applicant = omit(changeObject.applicant, ['incomeRange', 'turnoverRange', 'industry', 'firstName', 'lastName']);
  return changeObject;
};
