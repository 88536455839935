import React, { useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import BackArrow from '@/public/icons/back-arrow.svg';
import { LocaleContext } from '@/contexts/locale-context';
import { AuthContext } from '@/contexts/auth-context';
import { MerchantContext } from '@/contexts/merchant-context';
import { AdminContext } from '@/contexts/admin-context';
import { RoutingContext } from '@/contexts/routing-context';
import UserMenuDropdown from '../user-menu-dropdown';

export default function HeaderBarMenu({ merchantName }) {
  const { clearAuth } = useContext(AuthContext);
  const { getNavigationRoutes } = useContext(MerchantContext);
  const {
    isAdmin, isAdminReviewMode, adminModeMerchantId, getAdminFeature,
  } = useContext(AdminContext);
  const routes = (isAdmin && !isAdminReviewMode) ? getAdminFeature('navigation') : getNavigationRoutes();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const router = useRouter();
  const merchantId = router.query?.companyId;
  const { showInMenu, showInReviewMode } = useContext(RoutingContext);

  const { translate, getLanguageOptions } = useContext(LocaleContext);
  const [userMenuPage, setUserMenuPage] = useState(0);

  const isMultipleLanguageEnabled = getAdminFeature('multipleLanguageSupport');

  const mainMenu = [];

  let urlPrefix = '';

  if (isAdminReviewMode) {
    urlPrefix = `/merchants/${adminModeMerchantId}`;
  } else if (!isAdmin) {
    urlPrefix = `/${merchantId}`;
  }

  const addRoutes = (routeArray) => {
    routeArray.forEach((route) => {
      if (route.url && showInMenu(route.url)) {
        if (!isAdminReviewMode || (isAdminReviewMode && showInReviewMode(route.url))) {
          mainMenu.push({ title: translate(route.title), onClick: () => router.push(`${urlPrefix}${route.url}`), closeOnClick: true });
        }
      }
      if (route.subItems) {
        addRoutes(route.subItems);
      }
    });
  };

  if (isMobile) {
    addRoutes(routes);
  }

  if (!isAdmin || isAdminReviewMode) {
    mainMenu.push(
      { title: translate('users.pageTitle'), onClick: () => router.push(`${urlPrefix}/users`), closeOnClick: true },
      { title: translate('login.logout'), onClick: () => clearAuth() },
    );
  }

  if (isMobile && isAdmin && !isAdminReviewMode) {
    mainMenu.push({ title: translate('login.logout'), onClick: () => clearAuth() });
  }

  if (isMultipleLanguageEnabled) {
    mainMenu.splice(mainMenu.length - 1, 0, { title: translate('constants.lng'), onClick: () => setUserMenuPage(1) });
  }

  const populateMenuItems = () => {
    const languageMenu = [
      { title: translate('constants.selectLng'), icon: BackArrow, onClick: () => setUserMenuPage(0) },
    ];

    getLanguageOptions().forEach((language) => {
      languageMenu.push({
        title: language.title,
        icon: language.icon,
        onClick: language.onClick,
      });
    });

    return languageMenu;
  };

  const getMenuItems = (currentPage) => {
    if (currentPage === 1) {
      return populateMenuItems();
    }

    return mainMenu;
  };

  return (
    <UserMenuDropdown
      userName={merchantName}
      menuItems={getMenuItems(userMenuPage)}
      dropdownDisabled={!mainMenu.length}
      onClickAway={() => setUserMenuPage(0)}
    />
  );
}

HeaderBarMenu.propTypes = {
  merchantName: PropTypes.string.isRequired,
};
