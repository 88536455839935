/* eslint-disable no-console */
import axios from 'axios';
import { isEmpty } from '@/utilities/validation';
import generatePayload from '@/components/rule-dialog/generate-payload';
import compileChangeObject from './compile-change-object';
import getEnvVar from '../environment-variable';

export default async (changeArray, merchantId, token, rootOrgId) => {
  if (isEmpty(changeArray)) return;

  const { ruleBody = {} } = compileChangeObject(changeArray);

  const payload = generatePayload({
    ruleType: ruleBody.ruleType,
    companyId: merchantId,
    name: ruleBody.name,
    conditions: ruleBody.conditions,
    eventData: ruleBody.eventData,
    isDefault: ruleBody.isDefault,
    enabled: ruleBody.enabled,
  });

  const baseUrl = `${getEnvVar('REACT_APP_DODDLE_API_URL')}/v1/returns-portal-service/${rootOrgId}/company/${merchantId}/rule`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token,
  };

  try {
    if (ruleBody.ruleId) {
      // update the existing rule
      await axios.patch(`${baseUrl}/${ruleBody.ruleId}`, payload, { headers });
    } else {
      // create a rule
      await axios.post(baseUrl, payload, { headers });
    }
  } catch (e) {
    console.log('Could not update or create rule');
    console.log(e);
    throw new Error(e);
  }
};
