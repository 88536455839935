import * as Sentry from '@sentry/nextjs';

/**
 * Sends a non-error event to Sentry dashboard
 *
 * @param {Object} options configuration options object
 * @param {string} options.eventName name of the logged event, will appear as a tag value in Sentry dashboard
 * @param {string} options.message message text
 * @param {string} options.level log level, e.g 'info', 'warning', 'error'
 *
 */
const logEvent = ({ eventName, message, level = 'info' } = {}) => {
  Sentry.withScope((scope) => {
    scope.setTag('admin-portal-dashboard-event', eventName);
    scope.setLevel(level);
    Sentry.captureMessage(message);
  });
};

/**
 * Sends an exception event to Sentry dashboard
 *
 * @param {Object} options configuration options object
 * @param {string} options.eventName name of the logged event, will appear as a tag value in Sentry dashboard
 * @param {Error} options.exception exception object
 *
 */
const logException = ({ eventName, exception } = {}) => {
  Sentry.withScope((scope) => {
    scope.setTag('admin-portal-dashboard-event', eventName);
    scope.setLevel('error');
    Sentry.captureException(exception);
  });
};

export default {
  logEvent,
  logException,
};
