import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { AdminContext } from '@/contexts/admin-context';

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const { getAdminConfigValue } = useContext(AdminContext);
  const [textColor, setTextColor] = useState('#000');
  const [backgroundColor, setBackgroundColor] = useState('#f4f4f5');
  const theme = getAdminConfigValue('theme');

  const logoSizes = {
    small: {
      width: 280,
      height: 40,
    },
    medium: {
      width: 300,
      height: 60,
    },
  };

  return (
    <ThemeContext.Provider value={{
      textColor,
      setTextColor,
      backgroundColor,
      setBackgroundColor,
      primaryColor: theme?.primaryColor ?? '#4a90e2',
      hoverPrimaryColor: theme?.hoverPrimaryColor ?? '#32659e',
      logoBackground: theme?.logoBackground ?? false,
      alternateTextColor: theme?.alternateTextColor ?? '#000',
      buttonTextColor: theme?.buttonTextColor ?? null,
      headerLogoSize: logoSizes[theme?.headerLogoSize ?? 'medium'],
      loginAndSignupPageLogoSize: logoSizes[theme?.loginAndSignupPageLogoSize ?? 'medium'],
      publicAreaBackgroundColor: theme?.publicAreaBackgroundColor ?? null,
    }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContextProvider;
