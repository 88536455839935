import { CUSTOM_TEXT_API_VERSION, getAttributePathFromValueId, getCustomTextLocale } from '@/modules/page-customisation/utilities';

const settings = {
  RETURNS_DETAILS_TITLE: Symbol('return-details-title'),
  RETURNS_DETAILS_SUBTITLE: Symbol('return-details-subtitle'),
  RETURNS_DETAILS_ORDER_ID: Symbol('return-details-orderId'),
  RETURNS_DETAILS_TOOLTIP: Symbol('return-details-tooltip'),
  RETURNS_DETAILS_POLICY_LINK: Symbol('return-details-policy-link'),
  RETURNS_DETAILS_ORDERID_GHOST_TEXT: Symbol('return-detail-orderId-ghost-text'),
  SELECT_RETURNS_TITLE: Symbol('select-returns-title'),
  REASON_FOR_RETURN: Symbol('reason-for-return'),
  REASON_FOR_RETURN_PLACEHOLDER: Symbol('reason-for-return-placeholder'),
  RETURN_COMMENTS: Symbol('return-comments'),
  RETURN_COMMENTS_GHOST_TEXT: Symbol('return-comments-ghost-text'),
  SELECT_RETURN_METHOD_TITLE: Symbol('select-return-method-title'),
  BOOKING_CONFIRMATION_TITLE: Symbol('booking-confirmation-title'),
  BOOKING_CONFIRMATION_TEXT: Symbol('booking-confirmation-text'),
  BOOKING_CONFIRMATION_HINT: Symbol('booking-confirmation-hint'),
  RETURN_SUBMITTED_TITLE: Symbol('return-submitted-title'),
  RETURN_REQUEST_HEADING: Symbol('return-request-heading'),
  CONFIRMATION_EMAIL_HEADING: Symbol('confirmation-email-heading'),
  PRIMARY_BUTTON: Symbol('primary-button'),
  PRIMARY_BUTTON_URL: Symbol('primary-button-url'),
  PURCHASE_DETAILS_TITLE: Symbol('purchase-details-title'),
  PURCHASE_DETAILS_PURCHASE_DATE: Symbol('purchase-details-purchase-date'),
  PURCHASE_DETAILS_PURCHASE_DATE_PLACEHOLDER: Symbol('purchase-details-purchase-date-placeholder'),
  RETURN_REASON_COMMENTS: Symbol('return-reason-comments'),
  RETURN_REASON_COMMENTS_PLACEHOLDER: Symbol('return-reason-comments-placeholder'),
  RETURN_REASON_TITLE: Symbol('purchase-details-return-reason'),
  RETURN_REASON_PLACEHOLDER: Symbol('purchase-details-return-reason-placeholder'),
  ITEM_NAME_TITLE: Symbol('item-name-title'),
  ITEM_NAME_PLACEHOLDER: Symbol('item-name-placeholder'),
  CUSTOM_ATTRIBUTE_VARIANT: 'CUSTOM_ATTRIBUTE_VARIANT',
  CUSTOM_ATTRIBUTE_TITLE: 'CUSTOM_ATTRIBUTE_TITLE',
  CUSTOM_ATTRIBUTE_PLACEHOLDER: 'CUSTOM_ATTRIBUTE_PLACEHOLDER',
  CUSTOM_ATTRIBUTE_OPTIONS: 'CUSTOM_ATTRIBUTE_OPTIONS',
  RETURNS_OPTIONS_BLOCK: 'RETURNS_OPTIONS_BLOCK',
};

const getValue = ({
  merchantContext,
  changesContext,
  getLocaleContextValue,
  pageId,
  version,
  translate,
}) => (value) => {
  const settingsValue = settings[value];
  const currentLocale = getCustomTextLocale(getLocaleContextValue('currentLocale'));

  let pagesConfig;
  // get the v2 api values first, else fallback to legacy api
  if (version === CUSTOM_TEXT_API_VERSION.V2) {
    const languageConfig = merchantContext.getConfigValue('consumerText')?.v2?.[currentLocale] || {};
    const pageConfigs = {};
    // we need to merge all the pages into one object
    Object.values(languageConfig).forEach((page) => {
      Object.entries(page).forEach(([pageConfigValueKey, pageConfigValue]) => {
        pageConfigs[pageConfigValueKey] = pageConfigValue;
      });
    });
    pagesConfig = pageConfigs;
  }

  if (version === CUSTOM_TEXT_API_VERSION.V1 || !pagesConfig) {
    const languageConfig = merchantContext.getConfigValue('consumerText')?.languages?.find(({ language }) => language === currentLocale) || {};
    pagesConfig = languageConfig.steps?.find((step) => step.id === pageId)?.texts;
  }

  // If this is something with an outstanding change, take that
  const fieldChange = changesContext.changes.find((change) => change.valueId === value);
  if (fieldChange) {
    return fieldChange.value;
  }

  if (settingsValue === settings.RETURNS_DETAILS_TITLE) {
    const configValue = pagesConfig?.heading1;
    return configValue;
  }

  if (settingsValue === settings.RETURNS_DETAILS_SUBTITLE) {
    const configValue = pagesConfig?.heading2;
    return configValue;
  }

  if (settingsValue === settings.RETURNS_DETAILS_ORDER_ID) {
    if (version === CUSTOM_TEXT_API_VERSION.V1) {
      const v1ConfigValue = pagesConfig?.orderID || null;
      return v1ConfigValue;
    }
    // pull the heading1 from the v2 customisation
    // else pull the custom order id header if it has ever been set in V1
    const previouslySetOrderId = merchantContext.getConfigValue('returnFormFields')?.orderIdCustomHeader;
    const configValue = pagesConfig?.orderID ?? previouslySetOrderId;
    return configValue;
  }

  if (settingsValue === settings.RETURNS_DETAILS_TOOLTIP) {
    if (version === CUSTOM_TEXT_API_VERSION.V1) {
      const v1ConfigValue = pagesConfig?.orderIDTooltip || null;
      return v1ConfigValue;
    }
    const previouslySetOrderIdTooltip = merchantContext?.getConfigValue('orderInstructions');
    const configValue = pagesConfig?.orderIDTooltip ?? previouslySetOrderIdTooltip;
    return configValue;
  }

  if (settingsValue === settings.RETURNS_DETAILS_ORDERID_GHOST_TEXT) {
    if (version === CUSTOM_TEXT_API_VERSION.V1) {
      const v1ConfigValue = pagesConfig?.orderIDGhostText || null;
      return v1ConfigValue;
    }
    const previouslySetOrderId = merchantContext.getConfigValue('returnFormFields')?.orderIdCustomHeader;
    const shouldRenderV1Value = previouslySetOrderId && !pagesConfig.orderIDGhostText;
    const configValue = shouldRenderV1Value ? translate('customisation.orderPlaceholderWithPrefix', { orderIdCustomHeader: previouslySetOrderId }) : pagesConfig?.orderIDGhostText;
    return configValue;
  }

  if (settingsValue === settings.RETURNS_DETAILS_POLICY_LINK) {
    const configValue = pagesConfig?.returnPolicyLink;
    return configValue;
  }

  if (settingsValue === settings.SELECT_RETURNS_TITLE) {
    // needed for values with identical deeply nested keys
    if (version === CUSTOM_TEXT_API_VERSION.V2) {
      const languageConfig = merchantContext.getConfigValue('consumerText')?.v2?.[currentLocale] || {};
      const configValue = languageConfig?.integratedReturnList?.heading;
      return configValue;
    }
    const configValue = pagesConfig?.heading;
    return configValue;
  }

  if (settingsValue === settings.REASON_FOR_RETURN) {
    const configValue = pagesConfig?.reasonForReturn;
    return configValue;
  }

  if (settingsValue === settings.REASON_FOR_RETURN_PLACEHOLDER) {
    const configValue = pagesConfig?.reasonForReturnPlaceholder;
    return configValue;
  }

  if (settingsValue === settings.RETURN_COMMENTS) {
    const configValue = pagesConfig?.returnComments;
    return configValue;
  }

  if (settingsValue === settings.RETURN_COMMENTS_GHOST_TEXT) {
    const configValue = pagesConfig?.returnCommentsGhostText;
    return configValue;
  }

  if (settingsValue === settings.SELECT_RETURN_METHOD_TITLE) {
    // needed for values with identical deeply nested keys
    if (version === CUSTOM_TEXT_API_VERSION.V2) {
      const languageConfig = merchantContext.getConfigValue('consumerText')?.v2?.[currentLocale] || {};
      const configValue = languageConfig?.printOption?.heading;
      return configValue;
    }
    const configValue = pagesConfig?.heading;
    return configValue;
  }

  if (settingsValue === settings.BOOKING_CONFIRMATION_TITLE) {
    const configValue = pagesConfig?.bookingConfirmationTitle;
    return configValue;
  }

  if (settingsValue === settings.BOOKING_CONFIRMATION_TEXT) {
    if (version === CUSTOM_TEXT_API_VERSION.V2) {
      const languageConfig = merchantContext.getConfigValue('consumerText')?.v2?.[currentLocale] || {};
      const configValue = languageConfig?.bookingConfirmation?.title;
      return configValue ?? pagesConfig?.whatToDoNext;
    }
    const configValue = pagesConfig?.whatToDoNext;
    return configValue;
  }

  if (settingsValue === settings.BOOKING_CONFIRMATION_HINT) {
    const configValue = pagesConfig?.bookingConfirmationHint;
    return configValue;
  }

  if (settingsValue === settings.RETURN_SUBMITTED_TITLE) {
    if (version === CUSTOM_TEXT_API_VERSION.V2) {
      const languageConfig = merchantContext.getConfigValue('consumerText')?.v2?.[currentLocale] || {};
      const configValue = languageConfig?.returnSubmitted?.title;
      return configValue;
    }
    const configValue = pagesConfig?.title;
    return configValue;
  }

  if (settingsValue === settings.RETURN_REQUEST_HEADING) {
    const configValue = pagesConfig?.returnRequestHeading;
    return configValue;
  }

  if (settingsValue === settings.CONFIRMATION_EMAIL_HEADING) {
    const configValue = pagesConfig?.confirmationEmailHeading;
    return configValue;
  }

  if (settingsValue === settings.PRIMARY_BUTTON) {
    const configValue = pagesConfig?.primaryButton;
    return configValue;
  }

  if (settingsValue === settings.PRIMARY_BUTTON_URL) {
    const configValue = pagesConfig?.primaryButtonURL;
    return configValue;
  }

  if (settingsValue === settings.PURCHASE_DETAILS_TITLE) {
    const configValue = pagesConfig?.purchaseDetailsTitle;
    return configValue;
  }

  if (settingsValue === settings.PURCHASE_DETAILS_PURCHASE_DATE) {
    const configValue = pagesConfig?.purchaseDateTitle;
    return configValue;
  }

  if (settingsValue === settings.PURCHASE_DETAILS_PURCHASE_DATE_PLACEHOLDER) {
    const configValue = pagesConfig?.purchaseDatePlaceholder;
    return configValue;
  }

  if (settingsValue === settings.RETURN_REASON_COMMENTS) {
    if (version === CUSTOM_TEXT_API_VERSION.V1) {
      const v1ConfigValue = pagesConfig?.returnReasonCommentsTitle || null;
      return v1ConfigValue;
    }
    const previouslySetReturnReasonComments = merchantContext.getConfigValue('returnFormFields').reasonCommentsFieldName;
    const configValue = pagesConfig?.returnReasonCommentsTitle ?? previouslySetReturnReasonComments;
    return configValue;
  }

  if (settingsValue === settings.RETURN_REASON_COMMENTS_PLACEHOLDER) {
    const configValue = pagesConfig?.returnReasonCommentsPlaceholder;
    return configValue;
  }

  if (settingsValue === settings.RETURN_REASON_TITLE) {
    const configValue = pagesConfig?.returnReasonTitle;
    return configValue;
  }

  if (settingsValue === settings.RETURN_REASON_PLACEHOLDER) {
    const configValue = pagesConfig?.returnReasonPlaceholder;
    return configValue;
  }

  if (settingsValue === settings.ITEM_NAME_TITLE) {
    const configValue = pagesConfig?.itemNameTitle;
    return configValue;
  }

  if (settingsValue === settings.ITEM_NAME_PLACEHOLDER) {
    const configValue = pagesConfig?.itemNamePlaceholder;
    return configValue;
  }

  if (settingsValue === settings.RETURNS_OPTIONS_BLOCK) {
    const configValue = pagesConfig?.inStoreReturnsForm;
    return configValue;
  }

  if (value?.includes(settings.CUSTOM_ATTRIBUTE_VARIANT)) {
    return pagesConfig?.[getAttributePathFromValueId(value)]?.variant;
  }

  if (value?.includes(settings.CUSTOM_ATTRIBUTE_TITLE)) {
    return pagesConfig?.[getAttributePathFromValueId(value)]?.title;
  }

  if (value?.includes(settings.CUSTOM_ATTRIBUTE_PLACEHOLDER)) {
    return pagesConfig?.[getAttributePathFromValueId(value)]?.placeholder;
  }

  if (value?.includes(settings.CUSTOM_ATTRIBUTE_OPTIONS)) {
    return pagesConfig?.[getAttributePathFromValueId(value)]?.options;
  }
  return null;
};

export default getValue;

export const getIcon = () => ({ icon }) => ({
  icon,
  color: 'transparent',
});

export const getComponentProps = ({ globalEditEnabled, accentColor }) => () => ({ accentColor, globalEditEnabled });

export const customTextValueIds = Object.keys(settings);
