import React, {
  useState, createContext, useContext, useEffect,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import getEnvVar from '@/utilities/environment-variable';
import { MerchantContext } from '@/contexts/merchant-context';
import { AuthContext } from '@/contexts/auth-context';
import { LocaleContext } from '@/contexts/locale-context';
import { AdminContext } from '@/contexts/admin-context';
import qs from 'qs';
import logging from '@/utilities/telemetry/sentry';
import addressesUtils from '@/utilities/addresses';
import { isEmpty } from '@/utilities/validation';
import sessionStorage, { STORAGE_KEY_CARRIER_ADDRESSES_INFO } from '@/utilities/sessionStorage';

export const ValidateAddressContext = createContext({});
export const ValidateAddressContextConsumer = ValidateAddressContext.Consumer;

const { logException } = logging;

/**
 * Validate address request, to ensure that the address is valid
 * @param {string} token
 * @param {string} rootOrgId
 * @param {object | string} params
 * @return {Promise}
 */
export const validateAddressReq = async (token, rootOrgId, params) => {
  const apiUrl = getEnvVar('REACT_APP_DODDLE_API_URL');
  const queryString = typeof params === 'object' ? qs.stringify(params) : params;
  // API Validation doesn't work properly. Some values can be invalid but response will be success
  return axios.get(`${apiUrl}/v1/returns-portal-service/${rootOrgId}/validate-address?${queryString}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
};
/**
 * Validate the address string with our BE endpoint, to ensure that the address maps to a real
 * address IRL.
 *
 * @param children
 * @return {JSX.Element}
 */
const ValidateAddressContextProvider = ({ children }) => {
  const { translate } = useContext(LocaleContext);
  const { getAccessToken, getTokenType } = useContext(AuthContext);
  const {
    getRootOrgId, merchantId, getFeature, warehousesConfig, getDefaultWarehouse,
  } = useContext(MerchantContext);
  const { adminModeMerchantId, getAdminConfigValue } = useContext(AdminContext);
  const { multiCarrierEnabled } = getFeature('carriers');

  const [address, setAddress] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressCarrierRestrictions, setAddressCarrierRestrictions] = useState({ enabled: false, message: '' });
  const [addressInfoMessages, setAddressInfoMessages] = useState(sessionStorage.getData(STORAGE_KEY_CARRIER_ADDRESSES_INFO) || {});
  const [error, setError] = useState(null);
  const [addressCarrierError, setAddressCarrierError] = useState(null);
  const [invalidAddressFields, setInvalidAddressFields] = useState([]);

  const warehouse = getDefaultWarehouse();

  /**
   * @param {{
    * locationId: string,
    * message: string
    * invalidCarriers: Array.<string>
    * }} Options
    */
  const setInfoMessageForAddress = ({ locationId, message, invalidCarriers } = {}) => {
    if (locationId) {
      const updatedMessages = {
        ...addressInfoMessages,
        [locationId]: { message: addressCarrierRestrictions?.message || message, invalidCarriers },
      };
      setAddressInfoMessages(updatedMessages);
      sessionStorage.setData(STORAGE_KEY_CARRIER_ADDRESSES_INFO, updatedMessages);
    }
  };

  // Filters out carriers that can not ship to the provided location
  /**
   * @param {{
    * locationId: string,
    * carriers: Array.<object>
    * }} Options
    */
  const filterCarrierWithAddressRestrictions = ({ locationId, carriers = [] } = {}) => {
    const invalidCarriersForWarehouse = addressInfoMessages[locationId]?.invalidCarriers || [];
    const filteredCarriers = carriers.filter((carrierObj) => !invalidCarriersForWarehouse.includes(carrierObj.carrierCompanyId));
    return filteredCarriers;
  };

  /**
   * @param {{
   * warehouseAddress: string,
   * allowAnyValidCarrier: boolean
   * }} Options
   */
  const makeValidateCarrierRequest = async ({ warehouseAddress, allowAnyValidCarrier }) => {
    const companyIdString = `&companyId=${merchantId || adminModeMerchantId}`;
    let carrierValidation;
    try {
      const token = `${getTokenType()} ${getAccessToken()}`;
      const params = `${warehouseAddress + companyIdString}&checkAllCarriers=true`;

      const { data: validationResult } = await validateAddressReq(token, getRootOrgId(), params);

      carrierValidation = addressesUtils.checkAddressIsValidForCarriers(
        validationResult, {
          allowAnyValidCarrier,
          translate,
          supportEmail: getAdminConfigValue('emailAddresses')?.multiCarrierSupportEmail,
        },
      );
    } catch (exception) {
      logException({
        eventName: 'address_lookup_failure',
        exception,
      });
    }
    return carrierValidation;
  };

  /**
   * @param {string} addressToCheck - The address string we will send to the api. Defaults to the validate address variable
   */
  const makeValidationRequest = async (addressToCheck) => {
    async function makeRequest() {
      setLoading(true);
      setInvalidAddressFields([]);
      try {
        let validationResult;
        if (multiCarrierEnabled) {
          validationResult = await makeValidateCarrierRequest({
            warehouseAddress: addressToCheck,
            allowAnyValidCarrier: isEmpty(warehousesConfig),
          });

          if (validationResult.infoMessage) {
            setAddressCarrierRestrictions({ enabled: true, message: validationResult.infoMessage });
          }

          if (validationResult.error) {
            setAddressCarrierError(validationResult.error);
          }

          // when updating an address and it's a valid one - remove previous restrictions data
          if (!isEmpty(warehouse) && validationResult.found && !validationResult.infoMessage && !validationResult.error) {
            setAddressCarrierRestrictions({ enabled: true, message: '' });
          }
        } else {
          const companyIdString = `&companyId=${merchantId || adminModeMerchantId}`;
          const token = `${getTokenType()} ${getAccessToken()}`;
          const params = addressToCheck + companyIdString;
          const { data } = await validateAddressReq(token, getRootOrgId(), params);
          validationResult = data;
        }
        setIsValid(validationResult?.found);

        if (!validationResult?.found) {
          setInvalidAddressFields([
            'auto-complete-input',
            'address-town',
            'address-area',
            'address-postcode',
          ]);
        }
        setLoading(false);
      } catch (exception) {
        setError(exception);
        setLoading(false);
        logException({
          eventName: 'address_lookup_failure',
          exception,
        });
      }
      setAddress(null);
    }

    if (addressToCheck) {
      await makeRequest();
    }
  };

  const reset = () => { setIsValid(false); };

  useEffect(() => {
    makeValidationRequest(address);
  }, [address]);

  return (
    <ValidateAddressContext.Provider value={{
      loading,
      error,
      isValid,
      setIsValid,
      setAddress,
      invalidAddressFields,
      reset,
      addressCarrierRestrictions,
      setInfoMessageForAddress,
      addressInfoMessages,
      makeValidateCarrierRequest,
      filterCarrierWithAddressRestrictions,
      addressCarrierError,
    }}
    >
      {children}
    </ValidateAddressContext.Provider>
  );
};

ValidateAddressContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ValidateAddressContextProvider;
