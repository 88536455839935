import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from '@/contexts/locale-context';

export default function TextDiv({
  selectorId,
  children,
  className,
}) {
  const { textDirection } = useContext(LocaleContext);

  return (
    <div id={selectorId} dir={textDirection} className={className}>
      {children}
    </div>
  );
}

TextDiv.defaultProps = {
  selectorId: null,
  className: '',
};

TextDiv.propTypes = {
  selectorId: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
