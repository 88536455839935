import React from 'react';

function JaFontsOverride() {
  return (
    <>
      <div style={{ display: 'none' }} />
      <style jsx global>
        {`

            @font-face {
              font-family: "Yu Gothic Bold";
              src: local("Yu Gothic Bold");
              font-weight: normal;
            }
            @font-face {
              font-family: "Yu Gothic Bold";
              src: local("Yu Gothic Bold");
              font-weight: bold;
            }
            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Regular");
              font-weight: normal;
            }
            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Bold");
              font-weight: bold;
            }

            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Regular");
              font-weight: 200;
            }
            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Regular");
              font-weight: 300;
            }
            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Regular");
              font-weight: 400;
            }
            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Medium");
              font-weight: 500;
            }
            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Bold");
              font-weight: 600;
            }
            @font-face {
              font-family: "Yu Gothic Regular";
              src: local("Yu Gothic Bold");
              font-weight: 700;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Bold");
              font-weight: 800;
            }
            @font-face {
              font-family: "Yu Gothic Light";
              src: local("Yu Gothic Light");
              font-weight: normal;
            }
            @font-face {
              font-family: "Yu Gothic Light";
              src: local("Yu Gothic Medium");
              font-weight: bold;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Medium");
              font-weight: normal;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Bold");
              font-weight: bold;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Medium");
              font-weight: 200;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Medium");
              font-weight: 300;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Medium");
              font-weight: 400;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Medium");
              font-weight: 500;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Bold");
              font-weight: 600;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Bold");
              font-weight: 700;
            }
            @font-face {
              font-family: "Yu Gothic Medium";
              src: local("Yu Gothic Bold");
              font-weight: 800;
            }

            @font-face {
              font-family: "Hiragino Sans Bold";
              src: local(HiraginoSans-W7);
              font-weight: normal;
            }
            @font-face {
              font-family: "Hiragino Sans Bold";
              src: local(HiraginoSans-W7);
              font-weight: bold;
            }
            @font-face {
              font-family: "Hiragino Sans Light";
              src: local(HiraginoSans-W1);
              font-weight: normal;
            }
            @font-face {
              font-family: "Hiragino Sans Light";
              src: local(HiraginoSans-W3);
              font-weight: bold;
            }

            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W3);
              font-weight: normal;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W6);
              font-weight: bold;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W3);
              font-weight: 200;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W3);
              font-weight: 300;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W3);
              font-weight: 400;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W4);
              font-weight: 500;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W5);
              font-weight: 600;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W6);
              font-weight: 700;
            }
            @font-face {
              font-family: "Hiragino Sans Regular";
              src: local(HiraginoSans-W7);
              font-weight: 800;
            }

            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W5);
              font-weight: normal;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W7);
              font-weight: bold;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W5);
              font-weight: 200;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W5);
              font-weight: 300;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W5);
              font-weight: 400;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W6);
              font-weight: 500;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W6);
              font-weight: 600;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W7);
              font-weight: 700;
            }
            @font-face {
              font-family: "Hiragino Sans Medium";
              src: local(HiraginoSans-W7);
              font-weight: 800;
            }

            body {
              font-family: "Helvetica Neue", Helvetica, Arial, "Yu Gothic Medium",
                "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                "PingFang SC", "Hiragino Sans Regular", "Hiragino Sans", "Hiragino Sans GB",
                "Microsoft YaHei", sans-serif !important;
            }

        `}
      </style>
    </>
  );
}

export default JaFontsOverride;
