import AUSTRALIA_POST from './australia_post.json';
import USPS from './usps.json';
import YAMATO from './yamato.json';
import BLUE_YONDER from './blue_yonder.json';

export default {
  AUSTRALIA_POST,
  USPS,
  YAMATO,
  BLUE_YONDER,
};
