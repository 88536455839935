export default function updatePageIcons(instance) {
  const faviconElement = document.getElementById('favicon');
  faviconElement?.setAttribute('href', `/icons/${instance}/favicon.ico`);

  const icons = [
    'apple-touch-icon',
    'favicon-16x16',
    'favicon-32x32',
    'android-chrome-192x192',
    'android-chrome-512x512',
  ];

  icons.forEach((icon) => {
    const iconElement = document.getElementById(icon);
    iconElement?.setAttribute('href', `/icons/${instance}/${icon}.png`);
  });
}
