import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { MerchantContext } from '@/contexts/merchant-context';
import { AdminContext } from '@/contexts/admin-context';
import { AuthContext } from '@/contexts/auth-context';
import telemetryInstance from '@/utilities/telemetry';

export const TelemetryContext = createContext();

/**
 * Telemetry context
 * Allows us to send telemetry events with runtime information in the properties.
 *
 * @param {node} children The context children
 */
const TelemetryContextProvider = ({ children }) => {
  const { merchantId } = useContext(MerchantContext);
  const {
    carrier, isAdmin, isAdminReviewMode, adminModeMerchantId,
  } = useContext(AdminContext);
  const { getUserId, isUserLoggedIn } = useContext(AuthContext);
  const [isInitialised, setIsInitialised] = useState(false);

  const router = useRouter();
  const currentTelemetryRoute = (route) => {
    const baseRoute = route?.split('/').at(-1);
    return baseRoute;
  };

  const sendEvent = (eventName, eventProperties = {}) => {
    if (!isInitialised) {
      telemetryInstance.initTelemetry();
      setIsInitialised(true);
    }

    const userEmail = getUserId();
    const retailerId = adminModeMerchantId || merchantId;

    const fullProperties = {
      ...eventProperties,
      ...(carrier && { portalOwner: carrier }),
      ...(retailerId && { retailer: retailerId }),
      ...(userEmail && { email: userEmail }),
    };

    telemetryInstance.sendEvent(eventName, fullProperties);
  };

  const pageOpenEvent = useMemo(() => {
    if (isAdmin && !isAdminReviewMode) {
      return 'admin_page_opened';
    }
    return 'merchant_page_opened';
  }, [isAdmin, isAdminReviewMode]);

  // log iniitial page_open event
  useEffect(() => {
    if (isUserLoggedIn) {
      sendEvent(pageOpenEvent, { page_name: currentTelemetryRoute(router.route) });
    }
  }, []);

  // log page_open events on route change
  useEffect(() => {
    const handleRouteChange = (route) => {
      // only logs actual page navigations, not including pages with query params changing
      if (isUserLoggedIn && !route.includes('?')) {
        sendEvent(pageOpenEvent, { page_name: currentTelemetryRoute(route) });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [isUserLoggedIn, pageOpenEvent, isAdminReviewMode]);

  return (
    <TelemetryContext.Provider value={{
      sendEvent,
      currentTelemetryRoute,
    }}
    >
      {children}
    </TelemetryContext.Provider>
  );
};

TelemetryContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TelemetryContextProvider;
