/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import { RuleTypes } from './rules';

const doubleByteHyphenes = '\u30fc\u2015\uff0d\u2500';
const doubleByteKatakana = '\u30a1-\u30ff';
const doubleByteChars = '\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff21-\uff5a';
const doubleByteSymbols = '\u00a0-\u00df'
+ '\u0020-\u0030'
+ '\u2010-\u2040'
+ '\u2150-\u2473'
+ '\u2500-\u2600'
+ '\u3000-\u3039'
+ '\u30fb'
+ '\u3200-\u4dbf'
+ '\u4e00-\u9fff'
+ '\uff00-\uff0f'
+ '\uff1a-\uff65'
+ '\uffa0-\uffef';
const doubleByteNumbers = '\uff10-\uff19';
export const currencyRegex = /^\d+(\.\d{0,2})?$/;

const validPhoneNumber = (content) => {
  if (!content) return false;

  // contains invalid chars (0-9, whitespaces, '(', ')', '-', '+' are valid)
  if (/[^\d\-\s()+]/.test(content) || content.includes('--')) {
    return false;
  }

  const formattedValue = content.replace(/[-\s]/g, '');
  const digitsCount = (formattedValue.match(/\d/g) || []).length;
  const validLength = digitsCount >= 4 && digitsCount <= 15;

  // +XXXXXX or +XX(XXX)XXX
  const validFormat = /^\+?\d+$/.test(formattedValue) || /^\+?\d*\(\d+\)\d+$/.test(formattedValue);

  return validLength && validFormat;
};

const isMatchingPassword = (content, merchantConfig, changesContext) => {
  if (!content) return false;

  const currentPassword = changesContext.sharedData?.currentPassword;

  return currentPassword === content;
};

const validRegex = (content) => {
  try {
    const isValid = new RegExp(content);
    if (isValid) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const validWarrantyDaysNumber = (content, config) => {
  if (!content || !config) return false;

  const daysLimit = config.getFeature('returnPolicies').maxWarrantyDays;

  if (Number(content) > daysLimit) {
    return false;
  }
  return true;
};

const validDueDateDaysNumber = (content, config) => {
  if (!content || !config) return false;

  const daysLimit = config.getFeature('returnWindows').maxDueDateDays;

  if (Number(content) > daysLimit) {
    return false;
  }
  return true;
};

/**
 * Checks if the address is required but is empty
 * @param {string} _ - User input content.
 * @param {object} merchantConfig - Merchant config object, passed in from errorReporting HOC.
 * @param {object} changesContext - Changes context object, passed in from errorReporting HOC.
 * @return {Boolean}
 */
const isAddressRequiredButEmpty = (_, merchantConfig, changesContext) => {
  const hasNoAddressChanges = changesContext?.changes?.filter((change) => change.type === 'WAREHOUSE').length === 0;
  const hasNoAddress = !merchantConfig?.getDefaultWarehouse();

  if (hasNoAddress && hasNoAddressChanges) {
    return false;
  }

  return true;
};

/**
 * Checks if rule name already exists. Only takes into account rules of the same type
 * @param {string} content - User input content.
 * @param {object} merchantConfig - Merchant config object, passed in from errorReporting HOC.
 * @param {object} changesContext - Changes context object, passed in from errorReporting HOC.
 * @return {Boolean}
 */
const isValidRuleName = (content, merchantConfig, changesContext) => {
  if (!content || !merchantConfig || !changesContext.sharedData?.currentRule) return false;

  const currentRule = changesContext.sharedData?.currentRule;

  const existingRulenames = merchantConfig.getRulesByType(currentRule.ruleType)?.map((rule) => {
    if (rule.ruleId !== currentRule.ruleId) {
      return rule.ruleDescription;
    }
    return false;
  }).filter(Boolean);

  if (existingRulenames.includes(content)) {
    return false;
  }

  return true;
};

/**
 * Checks if orderId has already been used.
 * @param {string} content - User input content.
 * @param {object} merchantConfig - Merchant config object, passed in from errorReporting HOC.
 * @param {object} changesContext - Changes context object, passed in from errorReporting HOC.
 * @return {Boolean}
 */
const isValidOrderId = (content, merchantConfig, changesContext) => {
  if (!content || !merchantConfig || !changesContext.sharedData?.checkedOrderId) return false;
  return content !== changesContext.sharedData?.checkedOrderId;
};

/**
 * Checks if exception for orderId has been already added. Checks the 'exceptionAlwaysReturnableItem' rule values
 * @param {string} content - User input content.
 * @param {object} merchantConfig - Merchant config object, passed in from errorReporting HOC.
 * @return {Boolean}
 */
const isValidException = (content, merchantConfig) => {
  if (!content || !merchantConfig) return false;

  const existingExceptions = merchantConfig
    .getRulesByType(RuleTypes.ORDER_EXCEPTION)
    ?.flatMap((rule) => rule.rule?.conditions?.all?.[0]?.value);

  if (existingExceptions.includes(content)) {
    return false;
  }

  return true;
};

const isRequiredForInternationalValid = (content, config, changesContext) => {
  const homeCountry = config.getFeature('international')?.homeCountryCode;
  const hasInternationalChanges = changesContext.changes.some((change) => {
    // check if any of international portals has been enabled
    if (change.valueId === 'PORTAL_INTERNATIONAL_ACTIVE') {
      const nonDomesticPortals = change.value?.filter((portalToggle) => portalToggle.code !== homeCountry);
      return nonDomesticPortals?.some((portalToggle) => portalToggle.value);
    }
    return false;
  });
  if (!content && hasInternationalChanges) {
    return false;
  }
  return true;
};

const validationOptions = {
  email: {
    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    error: 'validation.emailInvalidError',
  },
  password: {
    rule: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
    error: 'wl.validation.passwordError',
  },
  strongPassword: {
    rule: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{12,}$/,
    error: 'wl.validation.strongPasswordError',
  },
  confirmPassword: {
    rule: isMatchingPassword,
    error: 'validation.passwordsNotMatchError',
  },
  letters: {
    rule: /^[a-zA-Z]*$/,
  },
  companyName: {
    rule: /^[A-Za-z0-9_ ]+$/,
    error: 'validation.companyNameFormatError',
  },
  uppercaseLetters: {
    rule: /^[A-Z]*$/,
  },
  lettersOptionalHyphen: {
    rule: /^([a-z]+(-[a-z]+)*\s*)+$/i,
    error: 'validation.noSpecialCharsError',
  },
  noSpecialCharacters: {
    rule: /^[^[}{[\]<>!#$%^*]*$/,
    error: 'validation.noSpecialCharsError',
  },
  noSpecialCharactersAlt: {
    rule: /^[^<#&]*$/,
    error: 'wl.validationSpecError',
  },
  noSpecialCharactersExpanded: {
    rule: /^[^[}{[\]<>!#$£%^*@&]*$/,
    error: 'validation.noSpecialCharsExpandedError',
  },
  zipcode: {
    rule: /(^\d{5}$)|(^\d{5}[ -]{1}\d{4}$)/,
    error: 'wl.addressFields.postcode.invalidError',
  },
  postcodeSP: {
    rule: /^\d{6}$/,
    error: 'wl.addressFields.postcode.invalidError',
  },
  postcodeBE: {
    rule: /^\d{4}$/,
    error: 'wl.addressFields.postcode.invalidError',
  },
  postcodeES: {
    rule: /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
    error: 'wl.addressFields.postcode.invalidError',
  },
  postcodeUK: {
    rule: /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/,
    error: 'wl.addressFields.postcode.invalidError',
  },
  numbers: {
    rule: /^\d+$/,
  },
  mobileNumber: {
    rule: validPhoneNumber,
    error: 'wl.validation.phoneNumberRequiredError',
  },
  katakanaOptionalHyphen: {
    rule: new RegExp(`^[${doubleByteKatakana}${doubleByteHyphenes}]+$`),
  },
  fullWidth: {
    rule: new RegExp(`^[${doubleByteChars}${doubleByteSymbols}]+$`),
  },
  excludeFullWidthEmail: {
    rule: new RegExp('^[\\u0000-\\u007F]*$'),
    error: 'validation.emailInvalidError',
  },
  fullWidthOptionalNumbers: {
    rule: new RegExp(`^[${doubleByteChars}${doubleByteNumbers}${doubleByteSymbols}]+$`),
  },
  fullWidthOptionalHyphen: {
    rule: new RegExp(`^[${doubleByteChars}${doubleByteNumbers}${doubleByteHyphenes}]+$`),
  },
  fullWidthOptionalHyphenAndNumbers: {
    rule: new RegExp(`^[${doubleByteChars}${doubleByteNumbers}${doubleByteHyphenes}${doubleByteSymbols}]+$`),
  },
  singleByteChars: {
    rule: /^[a-zA-Z_]+$/,
    error: 'wl.signup.companyNameFormatError',
  },
  singleByteLogin: {
    rule: new RegExp('^[\\u0000-\\u007F]*$'),
    error: 'wl.login.loginFormatError',
  },
  zipcodeAU: {
    rule: /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
    error: 'wl.addressFields.postcode.invalidError',
  },
  website: {
    rule: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    error: 'validation.websiteInvalidError',
  },
  httpProtocol: {
    rule: /^(ftp|http|https):\/\/[^ "]+$/,
    error: 'validation.urlProtocolError',
  },
  regex: {
    rule: validRegex,
    error: 'validation.regexInvalidError',
  },
  maxWarrantyDays: {
    rule: validWarrantyDaysNumber,
    error: 'validation.maxWarrantyDaysError',
  },
  maxDueDateDays: {
    rule: validDueDateDaysNumber,
    error: 'validation.maxDueDateDaysError',
  },
  validRuleName: {
    rule: isValidRuleName,
    error: 'validation.ruleNameInUse',
  },
  validException: {
    rule: isValidException,
    error: 'validation.exceptionAlreadyExists',
  },
  requiredForInternational: {
    rule: isRequiredForInternationalValid,
    error: 'validation.internationalRequiredError',
  },
  chargeAccountAU: {
    rule: /^[0-9]{5,7}$/,
    error: 'validation.chargeAccountAUError',
  },
  merchantLocationIdAU: {
    rule: /^[a-z0-9]{3}$|^[a-z0-9]{5}$/i,
    error: 'validation.merchantLocationIdAUError',
  },
  bpostAccountNumber: {
    rule: /^[a-z0-9]{6}$|^[a-z0-9]{6}$/i,
    error: 'validation.bpostAccountNumberError',
  },
  dpdAccountNumber: {
    rule: /^[a-z0-9]{6}$|^[a-z0-9]{7}$/i,
    error: 'validation.dpdAccountNumberError',
  },
  glsESAccountNumber: {
    rule: /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/i,
    error: 'validation.glsESAccountNumberError',
  },
  abnNumberAU: {
    rule: /^\d{11}$/,
    error: 'validation.abnNumberAUError',
  },
  accountIdSGP: {
    rule: /^\d{7}[a-zA-Z]$/,
    error: 'validation.accountIdSGPError',
  },
  phoneNumberSGP: {
    rule: /^(?=.*\d)[\d$¢£¥€₹!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8}$/,
    error: 'wl.validation.phoneNumberRequiredError',
  },
  nonEmpty: {
    rule: /.*\S.*/,
    error: 'validation.invalidValue',
  },
  currency: {
    rule: /^\d+(\.\d{1,2})?$/,
    error: 'validation.invalidCurrencyError',
  },
  price: {
    rule: /^\d+(\.\d{0,2})?$/,
    error: 'validation.invalidCurrencyError',
  },
  uspsAccountCode: {
    rule: /^\S{32}$/,
    error: 'validation.accountCodeRequiredError',
  },
  uniqueOrderId: {
    rule: isValidOrderId,
    error: 'validation.orderIdUsedError',
  },
  doubleSpace: {
    rule: /^(?:(?!\s{2}).)*$/,
    error: 'validation.doubleSpaceError',
  },
  username: {
    rule: /^[A-Za-z0-9_]\S+$/,
    error: 'validation.usernameError',
  },
  addressRequiredButEmpty: {
    rule: isAddressRequiredButEmpty,
    error: 'returnAddresses.addAddressMessage',
  },
};

/**
 * Checks if argument(value) is empty object, array or just a falsy value
 * @return {Boolean}
 * @param value any value
 */
export const isEmpty = (value) => {
  if (!value) return true;
  if (Array.isArray(value) && value.length === 0) return true;
  return typeof value === 'object' && Object.keys(value).length === 0;
};

export const removeEmpties = (object) => Object.entries(object).reduce((total, [key, value]) => {
  if (!value) return total;
  return {
    ...total,
    [key]: value,
  };
}, {});

export default {
  validationOptions,
};
