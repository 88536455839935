import React from 'react';
import PropTypes from 'prop-types';

export default function LoadingArc({ loadingText, inline, color }) {
  return (
    <div className={inline ? '' : 'wrapper'}>
      <div className={inline ? 'inline-loading-arc' : 'loading-arc'}>
        <div className="element" />
        <div className="element" />
        <div className="element" />
        <div className="element" />
        <span>{loadingText}</span>
      </div>
      <style jsx>
        {`
          @keyframes animation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          .wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .loading-arc {
            position: relative;
            width: 64px;
            height: 64px;
          }

          .inline-loading-arc {
            position: relative;
            width: 32px;
            height: 32px;
          }

          @media only screen and (max-width: 768px) {
            .loading-arc {
              transform: translate(0, -50%);
            }
          }

          .element {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: ${inline ? '24px' : '51px'};
            height: ${inline ? '24px' : '51px'};
            margin: 4px;
            border: 4px solid ${color};
            border-radius: 50%;
            animation: animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: ${color} transparent transparent transparent;
            left: 0;
            top: 0;
          }

          .element:nth-child(1) {
            animation-delay: -0.45s;
          }

          .element:nth-child(2) {
            animation-delay: -0.3s;
          }

          .element:nth-child(3) {
            animation-delay: -0.15s;
          }

          span {
            position: absolute;
            bottom: -60px;
            width: 300px;
            left: -125px;
            font-size: 24px;
            font-weight: bold;
            line-height: 1.75;
            text-align: center;
            color: #282d32;
          }
        `}
      </style>
    </div>
  );
}

LoadingArc.defaultProps = {
  loadingText: '',
  inline: false,
  color: '#4a90e2',
};

LoadingArc.propTypes = {
  loadingText: PropTypes.string,
  inline: PropTypes.bool,
  color: PropTypes.string,
};
