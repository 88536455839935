import amplitude from 'amplitude-js';
import getEnvVar from '@/utilities/environment-variable';

const apiKey = getEnvVar('REACT_APP_AMPLITUDE_API_KEY');

const sendEvent = (eventName, eventProperties) => {
  amplitude.getInstance().logEvent(eventName, eventProperties);
};

const init = () => {
  const instance = amplitude.getInstance();
  instance.init(apiKey, null, { includeReferrer: true });
};

export default {
  apiKey,
  init,
  sendEvent,
};
