/* eslint-disable no-console */
import axios from 'axios';
import getEnvVar from '@/utilities/environment-variable';
import { isEmpty } from '@/utilities/validation';
import { merge } from 'lodash';
import compileChangeObject from './compile-change-object';
import { enableCarrierInSingleCarrierMode } from './company-carrier';

const apiUrl = getEnvVar('REACT_APP_DODDLE_API_URL');
const createHeaders = (token) => ({
  'Content-Type': 'application/json',
  'cache-control': 'no-cache',
  Authorization: token,
});

export const updateWarehouseReq = ({
  locationId, rootOrgId, merchantId,
  body, token, multiCarrierAddressesApiEnabled,
}) => axios.patch(
  `${apiUrl}/v1/returns-portal-service/${rootOrgId}/company/${merchantId}${multiCarrierAddressesApiEnabled ? '/multicarrier' : ''}/warehouse/${locationId}`,
  body,
  {
    headers: createHeaders(token),
  },
);

export const createWarehouseReq = ({
  rootOrgId, merchantId, body,
  token, multiCarrierAddressesApiEnabled,
}) => axios.post(
  `${apiUrl}/v1/returns-portal-service/${rootOrgId}/company/${merchantId}${multiCarrierAddressesApiEnabled ? '/multicarrier' : ''}/warehouse`,
  body,
  {
    headers: createHeaders(token),
  },
);

export const deleteWarehouseReq = ({
  rootOrgId, merchantId, locationId, token, newDefaultAddressId,
}) => {
  let url = `${apiUrl}/v1/returns-portal-service/${rootOrgId}/company/${merchantId}/warehouse/${locationId}`;
  if (newDefaultAddressId) url += `?newDefault=${newDefaultAddressId}`;
  return axios.delete(
    url,
    {
      headers: createHeaders(token),
    },
  );
};

export const updateDefaultWarehouse = ({
  rootOrgId, merchantId, locationId, token, body,
}) => axios.patch(
  `${getEnvVar(
    'REACT_APP_DODDLE_API_URL',
  )}/v1/returns-portal-service/${rootOrgId}/company/${merchantId}/warehouse/${locationId}/default`,
  body,
  {
    headers: createHeaders(token),
  },
);

export default async (
  changeArray,
  merchantId,
  token,
  rootOrgId,
  existingAddress,
  newAddressEnabledByDefault,
  homeCountryCode,
  homeCountryCodeSetToWarehouse,
  getFeature,
  getConfigValue,
  productName,
) => {
  if (isEmpty(changeArray)) return;

  const changes = compileChangeObject(changeArray);

  const addressChanges = changes?.place?.address ?? {};
  const fieldsToMerge = changes?.mergedFields;
  const changesPresent = changes?.place?.address || fieldsToMerge;

  if (!changesPresent) return;

  const { multiCarrierAddressesApiEnabled } = getFeature('addresses');

  if (fieldsToMerge) {
    const addressPath = existingAddress?.place?.address;
    const existingAddressMap = {
      line3: addressPath?.line3,
      unitNumber: addressPath?.unitNumber,
      blockNumber: addressPath?.blockNumber,
    };

    const mergedInitialFields = merge(existingAddressMap, fieldsToMerge);
    const specialAddressFields = Object.values(mergedInitialFields);
    const mergedFields = specialAddressFields.filter(Boolean).join(',');

    if (!isEmpty(specialAddressFields)) {
      addressChanges.line2 = mergedFields?.length ? mergedFields : null;
    }
  }

  const body = {
    ...(!existingAddress && { companyId: merchantId }),
    ...(!existingAddress && { locationName: 'Main Address' }),
    enabled: multiCarrierAddressesApiEnabled ? undefined : newAddressEnabledByDefault ?? true,
    place: {
      address: {
        ...existingAddress?.place.address,
        ...addressChanges,
        ...(fieldsToMerge && { ...fieldsToMerge }),
        ...(homeCountryCode && homeCountryCodeSetToWarehouse && { country: homeCountryCode }),
      },
    },
    ...(!isEmpty(changes.externalBindings) && { externalBindings: changes.externalBindings }),
  };

  // try to create a warehouse if there isn't one, and set it as default
  if (!existingAddress) {
    try {
      const newWarehouse = await createWarehouseReq({
        rootOrgId, merchantId, body, token, multiCarrierAddressesApiEnabled,
      });
      // multi-carrier api handles setting the default warehouse
      if (multiCarrierAddressesApiEnabled) return;

      const locationId = newWarehouse.data?.resource?.locationId;

      try {
        await updateDefaultWarehouse({
          rootOrgId, merchantId, token, locationId, body,
        });
      } catch (e) {
        // if setting a default failed - need to clean up and throw error
        await deleteWarehouseReq({
          rootOrgId, merchantId, token, locationId,
        });
        console.log('Could not create a warehouse');
        console.log(e);
      }
    } catch (e) {
      console.log('Could not create a warehouse');
      console.log(e);
      throw new Error(e);
    }
  } else {
    await updateWarehouseReq({
      locationId: existingAddress.locationId,
      rootOrgId,
      merchantId,
      body,
      token,
      multiCarrierAddressesApiEnabled,
    });
  }
  // check if there's a pending carrier (single carrier mode) that needs to be activated
  const { carriersWithPendingStatus = [] } = getFeature('carriers') || {};
  const merchantCarriers = getConfigValue('carriersConfig');
  const pendingCarrier = merchantCarriers?.find((carrier) => carriersWithPendingStatus.includes(carrier.carrierCompanyId));

  if (pendingCarrier && !isEmpty(changes.externalBindings)) {
    await enableCarrierInSingleCarrierMode({
      merchantId,
      getConfigValue,
      carrierCompanyId: pendingCarrier.carrierCompanyId,
      rootOrgId,
      token,
      productName,
    });
  }
};
