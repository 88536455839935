import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LoadingArc from '@/components/loading-arc/';
import { ThemeContext } from '@/contexts/theme-context';
import { ValidationContext } from './validation-context';

/**
 * Basic submit button component
 *
 * @param {function} onSubmitFunction
 * @param {string} buttonLabel
 * @param {boolean} showSuccessEffect
 * @param {boolean} publishing
 * @param {string} inputSelectorId
 * @param {boolean} disabled
 * @param {string} colorOfDisabled
 * @param {boolean} useEnterButtonSubmission
 * @param {object} buttonRef
 * @return {JSX.Element}
 */
// eslint-disable-next-line import/prefer-default-export
export function Submit({
  onSubmitFunction,
  buttonLabel,
  showSuccessEffect,
  publishing,
  inputSelectorId,
  disabled,
  colorOfDisabled,
  buttonRef,
  useEnterButtonSubmission,
  loadingArcColor,
}) {
  const {
    setSubmitFunction,
    attemptSubmission,
  } = useContext(ValidationContext);
  const { primaryColor, hoverPrimaryColor, buttonTextColor } = useContext(ThemeContext);

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && useEnterButtonSubmission) {
      attemptSubmission();
    }
  };

  useEffect(() => {
    setSubmitFunction(() => onSubmitFunction);
  }, [onSubmitFunction]);

  return (
    <>
      <button
        id={inputSelectorId}
        ref={buttonRef}
        type="button"
        disabled={disabled}
        onClick={() => attemptSubmission()}
        onKeyDown={onKeyDown}
      >
        {publishing && <LoadingArc color={loadingArcColor} inline />}
        {buttonLabel}
      </button>
      <style jsx>
        {`
          button {
            align-items: center;
            cursor: pointer;
            background-color: ${showSuccessEffect ? 'var(--color-confirmation-green)' : `${primaryColor}`};
            border: none;
            border-radius: 21px;
            color: ${buttonTextColor || 'var(--color-white)'};
            display: ${publishing && 'flex'};
            gap: 5px;
            font-family: inherit;
            font-size: var(--font-size-body);
            font-weight: var(--font-weight-H1);
            height: 42px;
            min-height: 42px;
            min-width: 123px;
            padding: 10px 15px 10px 15px;
            width: auto;
          }

          button:hover {
            background-color: ${hoverPrimaryColor};
            transition: background-color 0.1s linear;
          }

          button:disabled {
            cursor: not-allowed;
            pointer-events: none;
            ${colorOfDisabled ? `background-color: var(${colorOfDisabled});` : 'opacity: 0.7;'}
          }
        `}
      </style>
    </>
  );
}
Submit.defaultProps = {
  inputSelectorId: '',
  disabled: false,
  buttonRef: null,
  showSuccessEffect: false,
  publishing: false,
  useEnterButtonSubmission: true,
  colorOfDisabled: undefined,
  loadingArcColor: 'var(--color-white)',
};

Submit.propTypes = {
  onSubmitFunction: PropTypes.func.isRequired,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  showSuccessEffect: PropTypes.bool,
  publishing: PropTypes.bool,
  inputSelectorId: PropTypes.string,
  disabled: PropTypes.bool,
  colorOfDisabled: PropTypes.string,
  useEnterButtonSubmission: PropTypes.bool,
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape(),
  ]),
  loadingArcColor: PropTypes.string,
};
