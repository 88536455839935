import OktaAuth from '@okta/okta-auth-js';

export default function Okta(authProps, localExternalAuth) {
  const oktaAuth = new OktaAuth({
    url: localExternalAuth ? authProps.localExternalAuthUrl : authProps.externalAuthUrl,
    clientId: localExternalAuth ? authProps.localEexternalAuthClientId : authProps.externalAuthClientId,
    redirectUri: `${window.location.origin}/login`,
    scopes: 'openid, profile, email',
    issuer: localExternalAuth ? authProps.localExternalAuthUrl : authProps.externalAuthUrl,
    tokenUrl: `${localExternalAuth ? authProps.localExternalAuthUrl : authProps.externalAuthUrl}/oauth2/v2.0/token`,
    authorizeUrl: `${localExternalAuth ? authProps.localExternalAuthUrl : authProps.externalAuthUrl}/oauth2/v2.0/authorize`,
    userinfoUrl: `${localExternalAuth ? authProps.localExternalAuthUrl : authProps.externalAuthUrl}/oauth2/v2.0/userinfo`,
  });

  const login = () => {
    oktaAuth.token.getWithRedirect();
  };

  const logout = () => {
    oktaAuth.tokenManager.remove('token');
    oktaAuth.signOut();
  };

  return {
    type: 'OKTA',
    key: authProps.externalAuthKey,
    grantType: authProps.externalAuthGrantType,
    login,
    logout,
  };
}
