/* eslint-disable no-console */
import { isEmpty } from '@/utilities/validation';
import { updateCompanyRequest } from './company';
import compileChangeObject from './compile-change-object';

export default async (changeArray, merchantId, token, rootOrgId, productName, getConfigValue, getFeature,
  { storeNetworkIds, networks }) => {
  if (isEmpty(changeArray)) return;

  const currentStoreNetworks = getConfigValue('storeNetworkConfig') || [];
  const changes = compileChangeObject(changeArray);

  const updatedStoreNetworks = storeNetworkIds?.map((id) => {
    const matchingNetwork = networks?.find((network) => network
      .storeNetworks.some((storeNetwork) => storeNetwork.storeNetworkId === id));

    return {
      storeNetworkId: id,
      enabled: true,
      storeNetworkCarrierId: matchingNetwork ? matchingNetwork.carrierCompanyId : null,
    };
  }) || [];

  const updatedList = currentStoreNetworks
    .filter((el) => !storeNetworkIds?.includes(el.storeNetworkId))
    .map((el) => ({ ...el, enabled: false }));

  const body = {
    products: {
      [productName]: {
        storeNetworkConfig: {
          selectedStoreNetworks: changes.deleteNetwork ? null : []
            .concat(updatedList)
            .concat(updatedStoreNetworks),
        },
        enabled: true,
      },
    },
  };
  try {
    await updateCompanyRequest({
      rootOrgId, merchantId, body, token,
    });
  } catch (e) {
    console.log('Could not update store network config');
    console.log(e);
    throw new Error(e);
  }
};
