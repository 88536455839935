/* eslint-disable react/react-in-jsx-scope */
import Link from 'next/link';

/**
 * Converts string to websafe format.
 * @param str
 * @returns {string}
 */
export const toWebSafeFormat = (str) => String(str)
  ?.toUpperCase()
  .replace(/[^A-Z0-9_\s]/g, '')
  .trim()
  .replace(/\s+/g, '_');

/**
 * Converts string to websafe format.
 * @param str
 * @returns {string}
 */
export const toWebSafeFormatDash = (str) => String(str)
  ?.toUpperCase()
  .replace(/[^A-Z0-9_\s]/g, '')
  .trim()
  .replace(/\s+/g, '-');

/**
 * Replaces a template tag with a passed in value. Self closing tags can be replaced by JSX,
 * non self closing tags require an object describing the substitution. For example
 * For example:
 * text = 'text <1>link<2/></1>
 * templates = {
 *  '<1>: { tag: 'a', href='blah' }, DESCRIBES ANCHOR
 *  '<2/>: <br />, SELF CLOSING, JSX SUBSTITUTION
 * }
 * @param {string} text - The line of text that we wish to make the replacement to
 * @param {Object} templates - Object describing the substitutions
 * @param {Array} tags - Array of tags to replace in the text
 * @returns {JSX.Element} - The new JSX with substitutions made
 */
function processTemplates({
  text, templates, tags,
}) {
  if (!text?.length) return null;
  if (!tags?.length) return text;

  const nextTag = tags[0];

  if (!templates[nextTag]) return text;

  const selfClosing = tags[0].endsWith('/>');
  const indexOfTemplateOpen = text.indexOf(nextTag);

  if (selfClosing) {
    const textAfterTag = text.slice(indexOfTemplateOpen + nextTag.length);
    let selfClosingElement;
    if (templates[nextTag].tag === 'img') {
      selfClosingElement = (
        <img src={templates[nextTag].src} alt={templates[nextTag].alt} />
      );
    } else {
      selfClosingElement = templates[nextTag];
    }
    return (
      <>
        {text.slice(0, indexOfTemplateOpen)}
        {selfClosingElement}
        {processTemplates({ text: textAfterTag, templates, tags: tags.slice(1) })}
      </>
    );
  }

  const templateValue = nextTag.replace(/<|>/g, '');
  const templateCloseIndex = tags.indexOf(`</${templateValue}>`);
  const closingTag = tags[templateCloseIndex];
  const indexOfTemplateClose = text.indexOf(closingTag);
  const remainingTags = tags
    .slice(1, templateCloseIndex)
    .concat(tags.slice(templateCloseIndex + 1));
  const innerText = text.substring(
    indexOfTemplateOpen + nextTag.length,
    indexOfTemplateClose,
  );

  let templateReplacement;
  // eslint-disable-next-line default-case
  switch (templates[nextTag].tag) {
    case 'a':
      templateReplacement = (
        <a href={templates[nextTag].href} rel="noopener noreferrer" target="_blank">
          {processTemplates({ text: innerText, templates, tags: remainingTags })}
        </a>
      );
      break;
    case 'internalLink':
      templateReplacement = (
        <span className="link">
          <Link href={templates[nextTag].href}>
            {processTemplates({ text: innerText, templates, tags: remainingTags })}
          </Link>
          <style jsx>
            {`
              .link  a {
                color: var(--color-primary);
                text-decoration: underline;
              }
            `}
          </style>
        </span>
      );
      break;
    case 'button':
      templateReplacement = (
        <button type="button" onClick={templates[nextTag].onClick}>
          {processTemplates({ text: innerText, templates, tags: remainingTags })}
        </button>
      );
      break;
    case 'strong':
      templateReplacement = (
        <strong>
          {processTemplates({ text: innerText, templates, tags: remainingTags })}
        </strong>
      );
      break;
    case 'p':
      templateReplacement = (
        <p>
          {processTemplates({ text: innerText, templates, tags: remainingTags })}
        </p>
      );
  }

  const textAfterClosingTag = text.slice(indexOfTemplateClose + closingTag.length);

  return (
    <>
      {text.slice(0, indexOfTemplateOpen)}
      {templateReplacement}
      {processTemplates({ text: textAfterClosingTag, templates, tags: remainingTags })}
    </>
  );
}

export const replaceTemplatesInString = ({
  text, templates, className,
}) => {
  if (!text?.length || !templates) {
    return text;
  }

  const tags = text.match(/<[^>]*>/g);

  return (
    <span className={className}>
      {processTemplates({
        text, templates, tags,
      })}
    </span>
  );
};

/**
 * Converts any url from a relative to an absolute URL.
 * For example:
 *  'return.com' becomes https://return.com
 * @param {string} url - The URL we want to convert to an absolute URL or append to the base URL
 * @returns {string} - An absolute URL string.
 * @returns {null} - If a wrong or empty url
 */

export const resolveURL = function resolve(url) {
  if (typeof url !== 'string') {
    return null; // wrong or empty url
  }
  // eslint-disable-next-line no-useless-escape
  if (url.match(/^[a-z]+\:\/\//i)) {
    return url; // url is absolute already
  }
  if (url.match(/^\/\//)) {
    return `https:${url}`; // url is absolute already
  }

  return `https://${url}`;
};
