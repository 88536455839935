import { LocaleContext } from '@/contexts/locale-context';
import React, { useContext, useState } from 'react';
import ChevronDownSmall from '@/public/icons/chevron-down-small.svg';
import ChevronUp from '@/public/icons/chevron-up.svg';
import ClickAway from '../click-away';
import MenuDropdown from '../menu-dropdown/menu-dropdown';

function LanguageSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const { translate, getLanguageOptions } = useContext(LocaleContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onKeyDown = (func) => (event) => {
    if (event.key === 'Enter' && func) {
      func();
    }
  };

  const menuItems = () => {
    const languageMenu = [
      { title: translate('constants.selectLng'), icon: ChevronUp, onClick: toggleMenu },
    ];
    getLanguageOptions().forEach((language) => {
      languageMenu.push({
        title: language.title,
        icon: language.icon,
        onClick: language.onClick,
        closeOnClick: true,
      });
    });

    return languageMenu;
  };

  const languageOptions = menuItems();

  return (
    <>
      <div className="selector-wrapper">
        <div
          onKeyDown={onKeyDown(toggleMenu)}
          role="button"
          tabIndex={0}
          onClick={toggleMenu}
          className="language-selector-label"
        >
          {translate('constants.lng')}
          <ChevronDownSmall />
        </div>
        <ClickAway shouldWeListen={isOpen} onClickAway={() => setIsOpen(false)}>
          {isOpen && (
            <MenuDropdown
              menuItems={languageOptions}
              closeMenu={toggleMenu}
              isOpen={isOpen}
              onKeyDown={onKeyDown}
              position="top-left"
            />
          )}
        </ClickAway>
      </div>
      <style jsx>
        {`

          .selector-wrapper {
            position: relative;
          }

          .language-selector-label {
            color: var(--color-primary-dark);
            cursor: pointer;
            display: flex;
            font-size: var(--font-size-footer);
            position: relative;
            padding: 3px 5px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 4px;
          }

        `}
      </style>
    </>
  );
}

export default LanguageSelector;
