/**
 * Helper method to grab the requested environment variable from the window object
 * where they will now live when using "react-env", so we don't get any nasty "object not found" errors.
 *
 * Check if the environment variable is on the window object first before using process.env
 *
 * @param environmentVariablePath
 */
const getEnvVar = (environmentVariablePath) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    const windowEnvVar = window._env?.[environmentVariablePath];
    if (windowEnvVar) {
      return windowEnvVar;
    }
  }

  return process.env[environmentVariablePath];
};

export default getEnvVar;
