import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TextDiv from '@/components/text-div';
import { ValidationContext } from './validation-context';

// eslint-disable-next-line import/prefer-default-export
export function FormError({ showOnlyRequiredError }) {
  const { formErrorMessage, anyRequiredMissing } = useContext(ValidationContext);

  const isErrorVisible = () => {
    if (showOnlyRequiredError) {
      return anyRequiredMissing();
    }
    return Boolean(formErrorMessage);
  };

  return (
    <>
      {(isErrorVisible() && formErrorMessage)
      && (
      <div className="form-error">
        <TextDiv selectorId="global-form-error-message" className="global-error">{formErrorMessage}</TextDiv>
      </div>
      )}
      <style jsx>
        {`
          .form-error {
            min-height: 16px;
            margin: 8px 0 10px;
          }
        `}
      </style>
    </>
  );
}

FormError.defaultProps = {
  showOnlyRequiredError: false,
};

FormError.propTypes = {
  showOnlyRequiredError: PropTypes.bool,
};
