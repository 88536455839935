import React from 'react';
import PropTypes from 'prop-types';
import TextDiv from '../text-div/text-div';

/**
 * @typedef {Object} Props
 * @property {Array} menuItems
 * @property {boolean} isOpen
 * @property {Function} closeMenu
 * @property {Function} onKeyDown
 * @property {string} position - top-left, top-right, bottom-left, bottom-right
 */

/**
 *
 * @param {Props} props
 * @returns JSX.Element
 */

function MenuDropdown({
  menuItems, isOpen, closeMenu, onKeyDown, position,
}) {
  return (
    <>
      <div className={`menu-items${isOpen ? ' open' : ''} ${position || ''}`}>
        {menuItems.map((item, index) => (
          <div
                // eslint-disable-next-line react/no-array-index-key
                /* eslint-disable react/no-array-index-key */
            key={`user-menu-${item.title}-${index}`}
            className="menu-items__item"
            onClick={(e) => {
              e.stopPropagation();
              if (item.closeOnClick) {
                closeMenu();
              }
              if (item.onClick) {
                item.onClick();
              }
            }}
            onKeyDown={onKeyDown(item.onClick)}
            role="button"
            tabIndex={0}
          >
            {item.icon && (
            <div className="menu-items__icon">
              <item.icon />
            </div>
            )}
            <TextDiv>{item.title}</TextDiv>
          </div>
        ))}
      </div>
      <style jsx>
        {`
          .menu-items {
            display: flex;
            flex-direction: column;
            position: ${position ? 'absolute' : 'relative'};
            width: 175px;
            cursor: pointer;
            padding: 8px 5px 6px;
            margin: 6px 0 2px;
            z-index: var(--zindex-dropdown);
            background-color: #fff;
            border-radius: var(--border-radius-small);
            box-shadow: rgba(0, 0, 0, 0.2) 0 0 3px 1px;
            visibility: hidden;
            height: 0;
          }

          .top-left {
            bottom: 0;
            right: 0;
          }

          .top-right {
            bottom: 0;
            left: 0;
          }

          .bottom-left {
            top: 0;
            right: 0;
          }

          .bottom-right {
            top: 0;
            left: 0;
          }
          .menu-items.open {
            visibility: visible;
            height: auto;
          }

          .menu-items__item {
            border-bottom: solid 1px rgba(151,151,151,0.25);
            width: 156px;
            height: auto;
            padding: 9px 2px;
            font-weight: normal;
            line-height: 22px;
            white-space: initial;
            cursor: pointer;
            margin: 0 5px;
            transition: background-color 0.3s;
            display: flex;
            flex-direction: row;
            user-select: none;
          }

          .menu-items__icon {
            position: relative;
            margin-right: 8px;
            width: 25px;
            height: 25px;
            line-height: 26px;
          }

          .menu-items__item:hover {
            background-color: #e6f7ff;
            transition: background-color 0.3s;
          }

          .menu-items__item:last-child {
            border-bottom: none;
          }
        `}
      </style>
    </>
  );
}

MenuDropdown.defaultProps = {
  position: undefined,
};

MenuDropdown.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func,
    closeOnClick: PropTypes.bool,
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  position: PropTypes.string,
};

export default MenuDropdown;
