/* eslint-disable no-console */
import Okta from './okta';
import useAppAuth from './app-auth';

export default (authProps, localExternalAuth) => {
  if (authProps?.externalAuthType === 'OKTA') {
    return Okta(authProps, localExternalAuth);
  }

  if (authProps?.externalAuthType === 'AUTHORIZATION_CODE') {
    return {
      type: 'authorization_code',
      key: authProps.externalAuthKey,
      grantType: authProps.externalAuthGrantType,
      login: () => null,
      logout: () => null,
    };
  }

  // Will need to work in other auth types here if needed
  const invalidAuthType = () => console.log(`Invalid auth type: ${authProps?.externalAuthType}`);
  return {
    type: 'Unknown',
    key: authProps.externalAuthKey,
    grantType: authProps.externalAuthGrantType,
    login: invalidAuthType,
    logout: invalidAuthType,
  };
};

export { useAppAuth };
