import logging from '@/utilities/telemetry/sentry';

const { logException, logEvent } = logging;

export const APP_ERRORS = {
  PERMISSIONS: 'constants.apiErrors.permissions',
  ADDRESS_NOT_VALID: 'wl.constants.addressNotValid',
  LOGIN_NOT_VALID: 'constants.apiErrors.loginNotValid',
  COMPANY_IS_DISABLED: 'constants.apiErrors.companyIsDisabled',
  TRADING_NAME_EXISTS: 'constants.apiErrors.tradingNameExists',
  ADD_CARRIER_ERROR: 'carriers.addCarrierError',
  DEFAULT: 'constants.apiErrors.default',
};

export const SSO_ERRORS = {
  ERR_INVALID: 'constants.apiErrors.invalidIDPToken',
  CODE_EXPIRED: 'constants.apiErrors.codeExpired',
  DEFAULT: 'constants.apiErrors.defaultIDP',
};

const extractErrorMessage = (error) => {
  if (typeof error === 'string') {
    return error;
  }

  if (error?.response?.data?.errors?.[0]?.message) {
    return error.response.data.errors[0].message;
  }

  if (typeof error?.message === 'string') {
    return error.message;
  }

  return '';
};

export const errorHandler = {
  defaultError: (err) => {
    if (Object.values(APP_ERRORS).includes(err?.message)) return err?.message;
    return APP_ERRORS.DEFAULT;
  },
  checkInvalidLogin: (err, user, companyid, authorizationCode) => {
    if (err.response?.authorizationCodeErrorCode) {
      if (err.response.authorizationCodeErrorCode === 'ERR_INVALID') {
        logException({ eventName: 'login_failure_idp', exception: err });
        return SSO_ERRORS.ERR_INVALID;
      }
      logException({ eventName: 'defaultIDP"', exception: err });
      return SSO_ERRORS.ERR_INVALID;
    }
    if (Object.values(APP_ERRORS).includes(err?.message)) {
      logException({ eventName: 'login_failure', exception: err });
      return err?.message;
    }
    if (authorizationCode && err.response?.data?.httpStatus === 401) {
      logException({ eventName: 'login_failure_auth-code', exception: err });
      return SSO_ERRORS.CODE_EXPIRED;
    }
    if (err.response?.data?.httpStatus === 401) {
      logException({ eventName: 'login_failure_credentials', exception: err });
      logEvent({ eventName: 'login_failure_credentials_message', message: `User ${user} tried to login with invalid credentials for company ${companyid}` });
      return APP_ERRORS.LOGIN_NOT_VALID;
    }
    logException({ eventName: 'login_failure', exception: err });
    return APP_ERRORS.DEFAULT;
  },
  checkInvalidTradingName: (err) => {
    if (extractErrorMessage(err) === 'Request with the same trading name already exists') {
      return APP_ERRORS.TRADING_NAME_EXISTS;
    }
    return APP_ERRORS.DEFAULT;
  },
  checkInvalidCarrierError: (err) => {
    if (extractErrorMessage(err) === 'Cannot add carrier for merchant') {
      return APP_ERRORS.ADD_CARRIER_ERROR;
    }
    return APP_ERRORS.DEFAULT;
  },

};
