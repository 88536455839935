import React, { useContext } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import TextDiv from '@/components/text-div';
import { ThemeContext } from '../../contexts/theme-context';

export default function SideNavigationSelection({ isSelected, url, title }) {
  const { primaryColor } = useContext(ThemeContext);
  return (
    <div>
      <TextDiv>
        <span className={`link${isSelected ? ' selected' : ''}`}>
          <Link href={url}>{title}</Link>
        </span>
      </TextDiv>
      <style jsx>
        {`
          div {
            position: relative;
            margin-left: 20px;
            font-size: 14px;
            padding: 6px 0;
          }

          .link:hover {
            opacity: 0.6;
          }

          .link:before {
            background-color: #d8d8d8;
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 11px;
            left: -20px;
          }

          .link.selected:before {
            background-color: ${primaryColor}
          };
        `}
      </style>
    </div>
  );
}

SideNavigationSelection.defaultProps = {
  isSelected: false,
};

SideNavigationSelection.propTypes = {
  isSelected: PropTypes.bool,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
