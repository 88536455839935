import { isEmpty } from '@/utilities/validation';
import {
  CUSTOM_TEXT_API_VERSION, compileLegacyConfigUpdates, compileV2ConfigUpdates, getCustomTextLocale,
} from '@/modules/page-customisation/utilities';
import { isFinite } from 'lodash';

/**
 * This helper creates changes object for 'custom text' feature.
 * Builds changes for both versions of the api for backwards compatibility.
 */
export default ({
  changesObject, getConfigValue, getLocaleContextValue, getFeature,
}) => {
  // If we get a mixed object of changes that are not version related we need to separate them from
  // the ones with the step as a key
  // we then simply add the changes that are outside of the step related changes to final object
  const changesWithKeysOtherThanNumber = Object.entries(changesObject).filter(([key]) => !isFinite(Number(key)));
  const changesWithKeysThatAreNumbers = Object.entries(changesObject).filter(([key]) => isFinite(Number(key)));
  const changesWithKeysThatAreNumbersObject = Object.fromEntries(changesWithKeysThatAreNumbers);
  const changesWithKeysOtherThanNumberObject = Object.fromEntries(changesWithKeysOtherThanNumber);
  // get current versions of configs from BE
  const existingLegacyConfig = getConfigValue('consumerText')?.languages;
  const existingV2Config = getConfigValue('consumerText')?.v2;

  const currentLocale = getCustomTextLocale(getLocaleContextValue('currentLocale'));
  const isV2VersionEnabled = getFeature('customisation')?.version === CUSTOM_TEXT_API_VERSION.V2;

  // default configs, used as a fallback if custom text config is not present on BE
  const defaultLegacyConfig = [{ language: currentLocale, steps: [] }];
  const defaultV2Config = { [currentLocale]: {} };

  // resolve which config to use for updates
  const legacyConfig = isEmpty(existingLegacyConfig) ? defaultLegacyConfig : existingLegacyConfig;
  const v2Config = isEmpty(existingV2Config) ? defaultV2Config : existingV2Config;

  // compile legacy api custom text changes
  const currentLocaleLegacyValues = legacyConfig.find(({ language }) => language === currentLocale);
  const currentLegacyLocale = currentLocaleLegacyValues || defaultLegacyConfig[0];
  const legacyLocaleUpdated = compileLegacyConfigUpdates(currentLegacyLocale, changesWithKeysThatAreNumbersObject);

  const legacyConfigUpdates = legacyConfig.filter(({ language }) => language !== currentLocale).concat(legacyLocaleUpdated);

  // compile v2 api custom text changes
  let v2ConfigUpdates;
  if (isV2VersionEnabled) {
    v2ConfigUpdates = compileV2ConfigUpdates(v2Config[currentLocale], changesWithKeysThatAreNumbersObject);
  }

  return {
    ...changesWithKeysOtherThanNumberObject,
    consumerText: {
      languages: legacyConfigUpdates,
      ...(isV2VersionEnabled && {
        v2: {
          ...v2Config,
          [currentLocale]: v2ConfigUpdates,
        },
      }),
    },
  };
};
