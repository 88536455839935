/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import getEnvVar from '@/utilities/environment-variable';
import { isEmpty } from '@/utilities/validation';
import { customTextValueIds } from '@/mappings/customisation';
import logging from '@/utilities/telemetry/sentry';
import compileChangeObject from './compile-change-object';
import compileConsumerTextChanges from './compile-consumer-text-changes';

const { logException } = logging;

const compileConfig = (changeArray, getConfigValue, getFeature, getLocaleContextValue) => {
  const marketingImageChange = changeArray.find((change) => change.valueId === 'MARKETING');
  if (marketingImageChange) {
    changeArray.push({
      type: 'APPLICATION_CONFIG_MERCHANT',
      value: Boolean(marketingImageChange.value),
      key: {
        isMarketingAssetsVisible: '%VALUE%',
      },
    });
  }

  const consumerTextChange = changeArray.find(({ valueId }) => customTextValueIds.some((id) => valueId?.includes(id)));
  if (consumerTextChange) {
    const changesObject = compileChangeObject(changeArray);
    return compileConsumerTextChanges({
      changesObject, getConfigValue, getLocaleContextValue, getFeature,
    });
  }

  const configChanges = changeArray.filter((change) => change.key?.type !== 'image');
  return compileChangeObject(configChanges);
};

const compileFileList = (changeArray) => {
  const fileChanges = changeArray.filter((change) => change.key?.type === 'image' && change.value);
  return fileChanges.map((change) => compileChangeObject([change]));
};

export default async (changeArray, organisationName, token, productName, version, getConfigValue, getFeature, getLocaleContextValue) => {
  if (isEmpty(changeArray)) return;

  const baseUrl = `${getEnvVar('REACT_APP_DODDLE_API_URL')}/v1/asset-management`;
  const headers = {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    Authorization: token,
  };

  const assetConfig = compileConfig(changeArray, getConfigValue, getFeature, getLocaleContextValue);

  const applicationConfig = getConfigValue('applicationConfig');

  if (isEmpty(applicationConfig)) {
    try {
      await axios.post(`${baseUrl}/${productName}/${organisationName}/1.0/`, assetConfig, { headers });
    } catch (e) {
      logException({ eventName: 'initial_application_config_failure', exception: { message: 'Could not create initial application-config', error: e } });
      throw new Error(e);
    }
  }

  const filesToUpload = compileFileList(changeArray);
  if (!isEmpty(filesToUpload)) {
    const uploads = filesToUpload.map(async (file) => {
      await axios.post(`${baseUrl}/${productName}/${organisationName}/${version}/upload`, file, { headers });
    });

    try {
      await Promise.all(uploads);
    } catch (e) {
      logException({ eventName: 'file_upload_failure', exception: { message: 'Could not upload files', error: e } });
      throw new Error(e);
    }
  }

  if (!isEmpty(assetConfig)) {
    try {
      await axios.patch(`${baseUrl}/${productName}/${organisationName}/${version}/`, assetConfig, { headers });
    } catch (e) {
      logException({ eventName: 'application_config_update_failure', exception: { message: 'Could not update application-config', error: e } });
      throw new Error(e);
    }
  }

  try {
    await axios.post(`${baseUrl}/${productName}/${organisationName}/${version}/publish`, {}, { headers });
  } catch (e) {
    logException({ eventName: 'application_config_publish_failure', exception: { message: 'Could not publish application-config', error: e } });
    throw new Error(e);
  }
};
