/* eslint-disable no-console */
import axios from 'axios';
import getEnvVar from '@/utilities/environment-variable';
import { isEmpty } from '@/utilities/validation';
import logging from '@/utilities/telemetry/sentry';
import compileChangeObject from './compile-change-object';

const { logException } = logging;

export const updateCompanyRequest = ({
  rootOrgId, merchantId, body, token,
}) => axios.post(
  `${getEnvVar('REACT_APP_DODDLE_API_URL')}/v1/returns-portal-service/${rootOrgId}/update-company/${merchantId || rootOrgId}`,
  body,
  {
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      Authorization: token,
    },
  },
);

export default async (
  changeArray, merchantId, token, rootOrgId, productName, getConfigValue, getFeature, sharedData, getAdminConfigValue,
) => {
  if (isEmpty(changeArray)) return;

  const body = {
    ...compileChangeObject(changeArray, productName, getConfigValue, getFeature, sharedData, getAdminConfigValue),
  };

  try {
    await updateCompanyRequest({
      rootOrgId, merchantId: merchantId || sharedData?.selectedMerchant, body, token,
    });
  } catch (e) {
    logException({ eventName: 'company_record_update_failure', exception: new Error(`Could not update the company record. Reason: ${e?.message || ''}`) });
    throw new Error(e);
  }
};
