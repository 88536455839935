import { isEmpty } from '@/utilities/validation';
import merge from 'deepmerge';
import { get, isNil, set } from 'lodash';

export const CUSTOM_TEXT_API_VERSION = {
  V1: 'v1',
  V2: 'v2',
};

const customFieldsTelemetryMap = {
  'return-details-title': {
    telemetryEvent: 'merchant_customisation_details_changed',
    telemetryCustomProperty: 'title',
  },
  'return-details-subtitle': {
    telemetryEvent: 'merchant_customisation_details_changed',
    telemetryCustomProperty: 'subtitle',
  },
  'return-details-orderId': {
    telemetryEvent: 'merchant_customisation_details_changed',
    telemetryCustomProperty: 'orderid',
  },
  'return-details-tooltip': {
    telemetryEvent: 'merchant_customisation_details_changed',
    telemetryCustomProperty: 'orderid_tooltip',
  },
  'return-details-orderId-ghost-text': {
    telemetryEvent: 'merchant_customisation_details_changed',
    telemetryCustomProperty: 'orderid_ghost_text',
  },
  'select-returns-title': {
    telemetryEvent: 'merchant_customisation_selectreturn_changed',
    telemetryCustomProperty: 'title',
  },
  'reason-for-return': {
    telemetryEvent: 'merchant_customisation_selectreason_changed',
    telemetryCustomProperty: 'reason_for_return',
  },
  'return-comments': {
    telemetryEvent: 'merchant_customisation_selectreason_changed',
    telemetryCustomProperty: 'return_comments',
  },
  'return-comments-ghost-text': {
    telemetryEvent: 'merchant_customisation_selectreason_changed',
    telemetryCustomProperty: 'return_comments_ghost_text',
  },
  'select-return-method-title': {
    telemetryEvent: 'merchant_customisation_method_changed',
    telemetryCustomProperty: 'title',
  },
  'booking-confirmation-text': {
    telemetryEvent: 'merchant_customisation_confirmation_changed',
    telemetryCustomProperty: 'confirmation_text',
  },
  'return-submitted-title': {
    telemetryEvent: 'merchant_customisation_booking_submit_changed',
    telemetryCustomProperty: 'title',
  },
  'return-request-heading': {
    telemetryEvent: 'merchant_customisation_booking_submit_changed',
    telemetryCustomProperty: 'request_heading',
  },
  'confirmation-email-heading': {
    telemetryEvent: 'merchant_customisation_booking_submit_changed',
    telemetryCustomProperty: 'email_heading',
  },
  'primary-button': {
    telemetryEvent: 'merchant_customisation_booking_submit_changed',
    telemetryCustomProperty: 'primary_button',
  },
  'primary-button-url': {
    telemetryEvent: 'merchant_customisation_booking_submit_changed',
    telemetryCustomProperty: 'button_url',
  },
};

/**
 * Compiles updates for legacy api custom text json config.
 * Example of data structure it uses: `consumerText.languages[0].steps[0].texts.heading1 = 'custom value'`.
 */
export const compileLegacyConfigUpdates = (configObject = {}, changesObject) => {
  const { steps } = configObject;

  Object.keys(changesObject).filter((key) => key !== 'valueId').forEach((pageId) => {
    const stepIndex = steps.findIndex(({ id }) => id === Number(pageId));

    let customTextObject = changesObject[pageId];

    // v2 version uses different data structure - so normalize it here before using with legacy api
    if (customTextObject.version === CUSTOM_TEXT_API_VERSION.V2) {
      [customTextObject] = Object.values(customTextObject.value);
    } else {
      customTextObject = customTextObject.value;
    }

    if (stepIndex !== -1) {
      steps[stepIndex] = merge(steps[stepIndex], { id: Number(pageId), texts: customTextObject });
    } else {
      steps.push({ id: Number(pageId), texts: customTextObject });
    }
  });

  return configObject;
};

// To avoid merging of array of options we remove them from the object and merge them separately
const handleOptionsChange = (configObject, changesObject) => {
  const isChangesObjectContainOptions = changesObject.valueId?.includes('CUSTOM_ATTRIBUTE_OPTIONS');
  // if changesObject contains options we need to remove them from configObject before merging
  if (isChangesObjectContainOptions) {
    const keyPath = (index) => `2.value.details.customAttribute${index}`;
    const optionsMap = new Map();
    // get options from changesObject and put them into map
    for (let i = 1; i < 6; i += 1) {
      const optionsChanges = get(changesObject, keyPath(i), null);
      const options = optionsChanges?.options?.filter((option) => !isNil(option.value));
      if (options) {
        optionsMap.set(i, options.options);
      }
    }

    const updatedConfigObject = configObject;
    // we need to remove options from configObject before merging to avoid merging of arrays
    optionsMap.forEach((value, key) => {
      set(updatedConfigObject, `details.customAttribute${key}.options`, []);
    });

    return { updatedConfigObject };
  }
  return { updatedConfigObject: configObject };
};

/**
 * Compiles updates for v2 api custom text json config.
 * Example of data structure it uses: `consumerText.v2.[locale].[pageName].heading1 = 'custom value'`.
 */
export const compileV2ConfigUpdates = (configObject, changesObject) => {
  let { updatedConfigObject: updatedV2Config } = handleOptionsChange(configObject, changesObject);
  Object.values(changesObject).forEach((updatedKey) => {
    if (updatedKey.version === CUSTOM_TEXT_API_VERSION.V2) {
      updatedV2Config = merge(updatedV2Config, updatedKey.value);
    }
  });

  return updatedV2Config;
};

/**
 * Escapes special characters in a string, so they can be stored in JSON.
 */
// eslint-disable-next-line no-control-regex
export const escapeCustomText = (val) => val?.replace(/(\n)|(")|(\x09)/g,
  (_, lineBreakMatch, doubleQuoteMatch, fullWidthSpace) => {
    if (lineBreakMatch) return '\\n';
    if (doubleQuoteMatch) return '\\"';
    if (fullWidthSpace) return '\\t';
    return '';
  });

/**
 * Unescapes lspecial characters coming from JSON, so they can be correctly rendered on the UI.
 */
export const unEscapeCustomText = (val) => val?.replace(/(\\n)|(\\")|(\\t)/g,
  (_, lineBreakMatch, doubleQuoteMatch, fullWidthSpace) => {
    if (lineBreakMatch) return '\n';
    if (doubleQuoteMatch) return '"';
    if (fullWidthSpace) return '\x09';
    return '';
  });

/**
 * Some locales use different keys in consumer/admin portals, this addresses the issue.
 */
export const getCustomTextLocale = (locale) => {
  const localesToConvert = {
    ja: 'jp',
  };
  return localesToConvert[locale] || locale;
};

/**
 * Filters out custom text fields for v2 api version, based on a merchant config settings
 */
export const filterV2CustomTextFields = (pageConfig, getConfigValue) => {
  const filteredV2fields = pageConfig.v2Fields?.map((rows) => rows.filter((pageField) => {
    let showPage = true;
    if (pageField.key === 'purchase-details-purchase-date') {
      showPage = getConfigValue('returnFormFields')?.purchaseDate;
    }

    if (pageField.key === 'return-details-orderId') {
      showPage = getConfigValue('returnFormFields')?.orderId;
    }
    if (pageField.key === 'return-details-policy-checkbox') {
      showPage = getConfigValue('termsAndConditionsUrl');
    }
    if (pageField.key === 'return-reason-comments') {
      showPage = getConfigValue('returnFormFields')?.reasonComments;
    }

    if (pageField.key === 'reason-for-return') {
      const anyReturnReasonEnabled = getConfigValue('returnReasonFields')?.reasons?.some((reason) => reason.enabled);
      showPage = anyReturnReasonEnabled;
    }

    if (pageField.key === 'purchase-details-return-reason') {
      const isReturnReasonEnabled = getConfigValue('returnReasonFields')?.enabled;
      const hasReturnReasons = getConfigValue('returnReasonFields')?.reasons?.length > 0;
      const anyReturnReasonEnabled = getConfigValue('returnReasonFields')?.reasons?.some((reason) => reason.enabled);
      showPage = isReturnReasonEnabled && hasReturnReasons && anyReturnReasonEnabled;
    }
    return showPage;
  }));
  return filteredV2fields;
};

/**
 * Applies current merchant config settings to v2 fields where needed
 */
export const applyConfigValuesToFields = (v2Fields, getConfigValue) => {
  const updatedFields = v2Fields?.map((rows) => rows.map((pageField) => {
    const updatedPageField = { ...pageField };
    if (pageField.key === 'return-reason-comments') {
      const isFieldRequired = getConfigValue('returnFormFields')?.reasonCommentsMandatory;
      if (isFieldRequired) {
        updatedPageField.params = {
          ...pageField.params,
          showAsRequired: true,
        };
      }
    }

    return updatedPageField;
  }));
  return updatedFields;
};

/**
 * Creates a unique valueId for a custom attribute field, based on valueId from the selector config and
 * the attributeId from the merchant config.
 * e.g. baseValueId 'CUSTOM_ATTRIBUTE_PLACEHOLDER' combined with 'customAttribute1' attributeId produces
 * 'CUSTOM_ATTRIBUTE_PLACEHOLDER_customAttribute1' valueId
 */
export const generateAttributeValueId = (baseValueId, attributeId) => (baseValueId ? `${baseValueId}_${attributeId}` : null);

/**
 * Compiles an updateKey path for custom attribute field. Takes base path from the field config
 * and the attribute path from the selector config and merges them together.
 * e.g. `"details": { "customAttribute1": "%ATTR_VALUE%" }` path becomes `"details": { "customAttribute1": { title: "%VALUE%" }}`
 */
export const getAttributeUpdateKey = (attributePath, valuePath) => {
  if (isEmpty(attributePath) || isEmpty(valuePath)) return null;

  const attributePathString = JSON.stringify(attributePath);
  const attributePathStringUpdated = attributePathString.replace('"%ATTR_VALUE%"', JSON.stringify(valuePath));
  const newAttributePath = JSON.parse(attributePathStringUpdated);
  return newAttributePath;
};

/**
 * Takes attributeId from the passed in valueId and generates a custom attribute path to get the value from:
 * e.g. 'CUSTOM_ATTRIBUTE_VARIANT_customAttribute1' valueId generates 'customAttribute1' update path
 */
export const getAttributePathFromValueId = (valueId) => valueId.split('_').at(-1);

/**
 * Adds telemetry events to fields from config. The events are stored in customFieldsTelemetryMap
 * where the key is a field id.
 */
export const addTelemetryToFields = (fields) => fields.map((field) => {
  const telemetryParams = customFieldsTelemetryMap[field.id];
  if (!telemetryParams) return field;
  return {
    ...field,
    params: {
      ...field.params,
      ...telemetryParams,
    },
  };
});

export default {
  CUSTOM_TEXT_API_VERSION,
};
