import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function ClickAway({
  children, shouldWeListen, onClickAway, clickedExternalInput,
  fullWidth,
}) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (shouldWeListen && !clickedExternalInput) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          onClickAway(event);
        }
      }
    }

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchdown', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchdown', handleClickOutside);
    };
  }, [wrapperRef, shouldWeListen]);

  return (
    <>
      <div className={fullWidth ? 'full-width' : ''} ref={wrapperRef}>{children}</div>
      <style jsx>
        {`
        .full-width {
          flex: 1;
        }
      `}
      </style>
    </>
  );
}

ClickAway.defaultProps = {
  clickedExternalInput: false,
  fullWidth: false,
};

ClickAway.propTypes = {
  children: PropTypes.node.isRequired,
  shouldWeListen: PropTypes.bool.isRequired,
  onClickAway: PropTypes.func.isRequired,
  clickedExternalInput: PropTypes.bool,
  fullWidth: PropTypes.bool,
};
