import React, {
  useState, createContext, useContext, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from '@/contexts/locale-context';
import { useRouter } from 'next/router';

export const ValidationContext = createContext();
export const ValidationContextConsumer = ValidationContext.Consumer;

/**
 * Wraps any list of input elements that need to have error checking.
 * This context has the boolean 'attemptSubmit' which when true will cause
 * all children to report whether or not they are valid.
 * Only if all elements are valid, it will allow the submission to take place
 *
 * @param children
 * @param {String} formErrorText - The text shown when there are required fields missing
 * @param {Function} onSubmitError - Called when there is a form error on submit
 * @return {JSX.Element}
 */
const ValidationContextProvider = ({
  children, formErrorText, onSubmitError, inputElementCount,
}) => {
  const router = useRouter();
  const { translate } = useContext(LocaleContext);
  const [elementResponses, setElementResponses] = useState([]);
  const [numberOfInputItems, setNumberOfInputItems] = useState(inputElementCount);
  const [submitFunction, setSubmitFunction] = useState(null);
  const [attemptSubmit, setAttemptSubmit] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  /**
   * Reset the list of elements that have reported themselves as valid,
   * then notify them that we have attempted a submission
  */
  const attemptSubmission = () => {
    setElementResponses([]);
    setAttemptSubmit(true);
  };
  const alreadyHaveAResponseFromElement = (elementId) => {
    const responsesWithThisId = elementResponses.filter((e) => e.id === elementId).length;
    return responsesWithThisId > 0;
  };

  const haveAllResponses = () => elementResponses.length === numberOfInputItems;
  const anyErrors = () => {
    const responsesThatAreNotValid = elementResponses.filter((e) => !e.valid);
    return responsesThatAreNotValid.length > 0;
  };
  const anyRequiredMissing = () => {
    const responsesThatAreRequiredButMissing = elementResponses.filter((e) => e.requiredEmpty);
    return responsesThatAreRequiredButMissing.length > 0;
  };
  const getErrorMessage = () => {
    const responsesThatAreNotValid = elementResponses.filter((e) => !e.valid);
    return responsesThatAreNotValid[0].error;
  };
  const updateValidity = ({
    id, valid, requiredEmpty, error,
  }) => {
    const foundIndex = elementResponses.findIndex((e) => e.id === id);
    if (foundIndex > -1) {
      const updatedResponses = elementResponses;
      updatedResponses[foundIndex] = {
        id, valid, requiredEmpty, error,
      };
      setElementResponses(updatedResponses);
    }
  };

  const verifyFormError = () => {
    if (attemptSubmit) {
      if (anyRequiredMissing()) {
        setFormErrorMessage(translate(formErrorText));
        return true;
      }

      if (anyErrors()) {
        setFormErrorMessage(getErrorMessage());
        return true;
      }

      setFormErrorMessage(null);
      return false;
    }

    setFormErrorMessage(null);
    return true;
  };

  /**
   * This gets called for an input element to report if it is valid
   *
   * @param {string} id unique element Id
   * @param {boolean} valid False if the input is invalid
   * @param {boolean} requiredEmpty False if it is a required field that is empty
   * @param {string} error Error message if invalid. null if the input is valid
   */
  const reportValidity = ({
    id, valid, requiredEmpty, error,
  }) => {
    if (alreadyHaveAResponseFromElement(id)) {
      updateValidity({
        id, valid, requiredEmpty, error,
      });
    } else {
      const updatedResponses = elementResponses;
      updatedResponses.push({
        id, valid, requiredEmpty, error,
      });
      setElementResponses(updatedResponses);
    }
    if (haveAllResponses()) {
      setAttemptSubmit(false);

      const hasErrors = verifyFormError();
      if (hasErrors) {
        onSubmitError(translate(formErrorText));
      } else {
        submitFunction();
      }
    }
  };

  const incrementInputItemCount = () => {
    // This uses the callback syntax in order to allow multiple updates per render loop
    setNumberOfInputItems((value) => value + 1);
  };

  const decrementInputItemCount = () => {
    setNumberOfInputItems((value) => value - 1);
  };

  const callSubmitFunction = useCallback(async () => {
    if (numberOfInputItems === 0 && submitFunction && attemptSubmit) {
      // submitFunction is the callback function passed in as a prop
      await submitFunction();
      // without setting attemptSubmit to false, the subsequent calls to submitFunction will not work
      setAttemptSubmit(false);
    }
  }, [attemptSubmit]);

  useEffect(() => {
    callSubmitFunction();
  }, [callSubmitFunction]);

  useEffect(() => {
    if (formErrorMessage) {
      setFormErrorMessage(null);
    }
  }, [router.asPath]);

  return (
    <ValidationContext.Provider value={{
      setSubmitFunction,
      attemptSubmit,
      attemptSubmission,
      incrementInputItemCount,
      decrementInputItemCount,
      numberOfInputItems,
      reportValidity,
      formErrorMessage,
      setFormErrorMessage,
      verifyFormError,
      updateValidity,
      anyRequiredMissing,
    }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

ValidationContextProvider.defaultProps = {
  formErrorText: 'constants.allFieldsError',
  onSubmitError: () => { },
  inputElementCount: 0,
};

ValidationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  formErrorText: PropTypes.string,
  onSubmitError: PropTypes.func,
  inputElementCount: PropTypes.number,
};

export default ValidationContextProvider;
