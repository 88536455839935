/* eslint-disable no-console */
/**
 * Abstraction from telemetry implementation. Connects up to amplitude, can be used to enable more/different
 * telemetry methods in the future without needing any code changes outside of this directory.
 */
import amplitude from './amplitude';

const shouldUseTelemetry = amplitude.apiKey && process.env.NODE_ENV === 'production';

/**
 * Called on app load to initialise the telemetry instance.
 * Doesn't do anything in dev mode as then we will only output events to the console
 *
 */
const initTelemetry = () => {
  if (shouldUseTelemetry) {
    amplitude.init();
  }
};

/**
 * Send a telemetry event.
 * Note that in dev mode we just log the event out to the console
 *
 * @param {string} eventName - The name of the event that we wish to send
 * @param {object} eventProperties - Object containing all of the event information that we wish to send
 */
const sendEvent = (eventName, eventProperties) => {
  if (shouldUseTelemetry) {
    amplitude.sendEvent(eventName, eventProperties);
  } else {
    console.log('%c [Event: %s] [Properties: %o]', 'color: #00B7F1', eventName, eventProperties);
  }
};

export default {
  initTelemetry,
  sendEvent,
};
